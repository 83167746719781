import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { Box, Flex, Text, Divider, Select } from '@chakra-ui/react';
import {eduBarChartData} from "../data/mock";

const EducationBarCharts = () => {

    const RoundedBar = (props) => {
        const { x, y, width, height } = props;
        const radius = 9; 
        return (
          <g>
          <path d={`M${x},${y + height} L${x},${y + radius} Q${x},${y} ${x + radius},${y} L${x + width - radius},${y} 
            Q${x + width},${y} ${x + width},${y + radius} L${x + width},${y + height} Z`} fill={props.fill} />
          </g>
        );
      };
      const formatYAxis = (tickValue) => {
        return tickValue !== 0 ? tickValue + 'k' : tickValue;
      };

  return (
    <Flex direction="column" alignItems="flex-start" bg={'#FFFFFF'} borderRadius={'20px'}  pr={3} >
      <Flex py={3} pl={5} justifyContent={'space-between'} alignItems={'center'}  w={'100%'} >
        <Text fontSize={'14px'} fontWeight={'500'} letterSpacing={'-0.154px'} color={'#4D4D4D'}>
            Total Number of Applicants per Status
        </Text>
        <Flex justifyContent={'space-between'} alignItems={'center'} gap={5} >
          <Select w={'100px'} border={'0'} focusBorderColor='white' color={'#1E1E1E'} opacity={'0.5'} fontSize={'12px'} fontWeight={'400'} fontFamily={'inter'} lineHeight={'14.52px'}>
            <option>This Year</option>
          </Select>
        </Flex>
      </Flex>
      <Flex w="100%" direction={['column', 'column', 'row']} py={4} alignItems={'center'} >
        <ResponsiveContainer width="100%" height={280} >
          <BarChart data={eduBarChartData} margin={{ top: 20, right: 10, left: 10, bottom: 5 }} barGap={10} barCategoryGap={10} >
            <XAxis dataKey="name" axisLine={false} tickLine={false} />
            <YAxis axisLine={false} tickLine={false} tickFormatter={formatYAxis} />
            <Tooltip />
            <Bar dataKey="approved" shape={<RoundedBar />}  fill="#D11A2A" barSize={15} />
            <Bar dataKey="inProgess" shape={<RoundedBar />}  fill="#F8D9DA" barSize={15} />
            <Bar dataKey="pending" shape={<RoundedBar />}  fill="#760156" barSize={15} />
            <Bar dataKey="rejected" fill="#EAE3EC" barSize={15} />
          </BarChart>
        </ResponsiveContainer>
        <Divider orientation="vertical" border={'1px solid #E4E5E7'}  h={"90%"} />
        <Flex direction="column" justify="center" >
          <Flex alignItems={'center'} gap={2}>
            <Box bg="#D11A2A" h={2} w={4} borderRadius="3px" ></Box>
            <Text fontSize="sm" color="#4D4D4D">
                Approved
            </Text>
          </Flex>
          <Flex alignItems={'center'} gap={2}>
            <Box bg="#F8D9DA" h={2} w={4} borderRadius="3px" ></Box>
            <Text fontSize="sm" color="#4D4D4D">
                In-progress
            </Text>
          </Flex>
          <Flex alignItems={'center'} gap={2}>
            <Box bg="#760156" h={2} w={4} borderRadius="3px" ></Box>
            <Text fontSize="sm" color="#4D4D4D">
                Pending
            </Text>
          </Flex>
          <Flex alignItems={'center'} gap={2}>
            <Box bg="#D7343930" h={2} w={4} borderRadius="3px" ></Box>
            <Text fontSize="sm" color="#4D4D4D">
              Rejected
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default EducationBarCharts;