import React from 'react';
import { HiUserGroup } from "react-icons/hi2";
import { PiSquaresFourBold } from "react-icons/pi";
import { Box, Flex, 
  // Text,
} from '@chakra-ui/react';
import DetailsCard from '../components/DetailsCard';
import {barChartData} from "../data/mock";
import DashboardBarChart from '../components/DashboardBarChart';
import MembershipPieChart from '../components/MembershipPieChart';

const MembershipOMDashboard = () => {
  const details = [
    { name: "Total Student", number: "250", bgcolor:"#EFC3CD87",  icon: <HiUserGroup size={"1.5rem"} color='#D11A2A' /> },
    { name: "Total NYSC", number: "90", bgcolor:"#758EFE40", icon: <PiSquaresFourBold size={"1.5rem"} color='#3064FC' /> },
    { name: "Total Graduate", number: "20", bgcolor:"#CD73D142", icon: <HiUserGroup size={"1.5rem"} color='#6C1270' /> },
    { name: "Total Associate", number: "20", bgcolor:"#222B455C", icon: <PiSquaresFourBold size={"1.5rem"} color='#000000' /> },
    { name: "Total Fellows", number: "20", bgcolor:"#FFC34F5C", icon: <PiSquaresFourBold size={"1.5rem"} color='#000000' /> }
  ];

  return (
    <Flex flexDirection={'column'} gap={'20px'} py={'30px'} minH={'100vh'} px={3} >
        <DetailsCard details={details} />
      <Flex alignItems={['center', 'center', 'flex-start']} flexDirection={['column', 'column','column', 'row']}  justifyContent={['center', 'center', 'flex-start']} gap={'20px'} >
        <Flex flexDirection={'column'} w={['100%', '100%', '100%','70%', '75%']} gap={5}>
          <DashboardBarChart barChartData={barChartData} />
        </Flex>
        <Box width={['100%', '100%', '100%', '30%', '25%']} >
          <MembershipPieChart />
        </Box>
      </Flex>
    </Flex>
  )
}

export default MembershipOMDashboard;