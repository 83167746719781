import React, {useState} from 'react';
import { Form, Formik } from "formik";
import { Button, FormControl, FormErrorMessage, Input, FormLabel, Flex,  Alert, AlertIcon, AlertTitle} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { setFormModal } from "../redux/slices/modalSlice";
import {fetchExamDiet} from "../redux/slices/examDietPeriodSlice";
import {setloadTost} from "../redux/slices/toastSlice";
import { BASE_URL } from "../config/settings";
import httpRequest from "../utils/httpRequest";

const EditDiet = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const errorsArray = Array.isArray(error) ? error : [error];
    const editExamDiet =  useSelector((state) => state?.examDietPeriod?.editExamDiet);

    const initialValues = {
      diet: editExamDiet?.name || '',
    };
  
    const handleSubmit = async (values) => {
      // console.log(values);
      setLoading(true);
      setError("");
      try {
        const formData = {
          name: values?.diet,
          isActive: true
        };

        const response = await httpRequest(
          `${BASE_URL}/v2/ExamDiet/${editExamDiet?.id}`,
          "put",
          formData
        );
        // console.log(response);
        if (response?.isSuccess === true){
          setLoading(false);
          dispatch(fetchExamDiet());
          dispatch(setFormModal({ status: false, data: null }));
          dispatch(setloadTost({ title: 'Diet Edited', description: `${values?.diet} diet edited successfully`, status: 'success', duration: 5000, isClosable: true, position: 'top' }));
        }else{
          setLoading(false);
          setError(response?.response?.data?.errors?.map((errors) => errors) || response?.response?.data?.error?.description || "Failed to Edit diet");
        }
        
      } catch (error) {
        setError(error?.payload?.message || "Failed to edit diet, please check your network");
      }finally{
        setLoading(false);
      }
    }

  return (
    <>
    {
      error &&(
      <Alert status="error" className="mt-3" mb={3}>
          <AlertIcon />
          <AlertTitle>{errorsArray.join(', ')}</AlertTitle>
      </Alert>
      )
    }
    <Formik
    enableReinitialize
    initialValues={initialValues}
    onSubmit={(values) => handleSubmit(values)}
    >
    {({ errors, touched, values, handleBlur, handleChange }) => (
      <Form>
        <FormControl mt={4} isInvalid={errors.diet && touched.diet}
              color="#121212" fontFamily={'inter'}>
            <FormLabel htmlFor='diet'  fontSize={"16px"}>Diet</FormLabel>
            <Input
              placeholder="Enter Here"
              fontSize={"small"}
              bg={'#F3F3F3'}
              name="diet"
              focusBorderColor="#ffffff"
              borderRadius={'0'}
              borderColor={"#FFFFFF"}
              _placeholder={{ color: "#B4B1B1" }}
              _hover={{ borderColor: "#FFFFFF" }}
              type="text"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values?.diet}
            />
            {touched.diet && errors.diet && (
              <FormErrorMessage>{errors.diet}</FormErrorMessage>
            )}
        </FormControl>
        
        <Flex  justifyContent={'center'} alignItems={"center"} gap={5}>
          <Button
            mt={10} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'}
            width={"118px"}
            background={"#D11A2A"}
            _hover={{ background: " " }}
            color={"#FFFFFF"}
            type="submit"
            isLoading={loading}
            loadingText="Saving ..."
          >
            Edit Diet
          </Button>
          <Button
            mt={10} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'}
            width={"118px"}
            background={""}
            _hover={{ background: " " }}
            color={"#8F9BB3"}
            onClick={() => {
              dispatch(setFormModal({ status: false, data: null }));
            }}
            loadingText="Saving ..."
          >
            Cancel
          </Button>
        </Flex>
      </Form>
    )}
  </Formik>
  </>
  )
}

export default EditDiet