import { Box, Flex, Text, Divider } from '@chakra-ui/react';
import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const DashboardLineChart = ({lineChartData}) => {

  return (
    <Flex direction="column" alignItems="flex-start" bg={'#FFFFFF'} borderRadius={'20px'}  pr={2} >
    <Flex py={3} pl={5} pr={3} justifyContent={'space-between'} alignItems={'center'}  w={'100%'} >
      <Text fontSize={'14px'} fontWeight={'500'} letterSpacing={'-0.154px'} color={'#4D4D4D'}>
        Total Members
      </Text>
      <Flex justifyContent={'space-between'} alignItems={'center'} gap={5} >
        <Divider orientation='vertical' border={'1px solid #1C1C1C33'} h="15px" mx={3} />
        <Flex alignItems={'center'}>
          <Box bg="#FF565B" h={2} w={2} borderRadius="50%" ></Box>
          <Text fontSize={'14px'} fontWeight={'400'} lineHeight={'18px'} fontFamily={'inter'} color={'#1C1C1C'} ml={2}> This Year</Text>
        </Flex>
        <Flex alignItems={'center'}>
          <Box bg="#9A9AA9" h={2} w={2} borderRadius="50%" ></Box>
          <Text fontSize={'14px'} fontWeight={'400'} lineHeight={'18px'} fontFamily={'inter'} color={'#9A9AA9'} ml={2}> Last Year</Text>
        </Flex>
      </Flex>
    </Flex>
    <Flex w="100%" direction="row">
    <ResponsiveContainer width="100%" height={300}>
      <LineChart data={lineChartData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 0" vertical={false} strokeWidth={0.2}  />
        <XAxis dataKey="name" axisLine={false} tickLine={false}   />
        <YAxis  axisLine={false} tickLine={false}   />
        <Tooltip />
        <Line type="monotone" dataKey="value" stroke="#FFC7D4" strokeWidth={3} dot={{ fill: '#A8C5DA', stroke: 'transparent', r: 4 }} />
      </LineChart>
    </ResponsiveContainer>
     
    </Flex>
  </Flex>
  )
}

export default DashboardLineChart;