import React, { useState } from 'react';
import {Box, Button, Flex, Image, Text, Textarea, Alert,AlertIcon, AlertTitle } from '@chakra-ui/react';
import decline from "../Assets/Images/decline-icon.png";
import { IoMdClose } from "react-icons/io";
import { setFormModal } from "../redux/slices/modalSlice";
import { useDispatch, useSelector } from 'react-redux';
import {setloadTost} from "../redux/slices/toastSlice";
import {memberUpgradeApprove, fetchMemberUpgradeApps} from "../redux/slices/membershipUpgradeSlice";

const MembershipDeclineForm = () => {
    const dispatch = useDispatch();
    const [comment, setComment] = useState('');
    const memberDetails = useSelector((state) => state.modal.formModal?.data);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const errorsArray = Array.isArray(error) ? error : [error];

    const handleSubmit = async() => {
        if (!comment) {
            dispatch(setloadTost({ title: 'Empty Field', description: 'Comment is required', status: 'warning', duration: 3000, isClosable: true, position: 'top' }));
            return;
        }
        try {
            const formData = {
                status:"Decline",
                comment: comment
            };

            const response = await dispatch(memberUpgradeApprove({arg: formData, id: memberDetails?.id}));
          console.log("response", response);
            if (response?.payload?.isSuccess === true){
                setLoading(false);
                dispatch(fetchMemberUpgradeApps("Pending"));
                dispatch(setFormModal({ status: false, data: null }));
                dispatch(setloadTost({ title: 'Approval', description: `${memberDetails?.fullName} Approved successfully`, status: 'success', duration: 5000, isClosable: true, position: 'top' }));
              }else{
                setLoading(false);
                setError(response?.payload?.errors?.map((errors) => errors) || response?.payload?.error?.description || "Failed to Approve");
              }
            
        } catch (error) {
            setError(error?.payload?.message || "Failed to Decline, please check your network");
        }finally{
            setLoading(false);
        }
    };

  return (
    <Box>
        <Flex justifyContent={'flex-end'} py={2} onClick={() => dispatch(setFormModal({ status: false, data: null }))}>
            <IoMdClose style={{cursor:'pointer', height:'24px', width:'24px'}} />
        </Flex>
        {
            error &&(
            <Alert status="error" className="mt-3" mb={3}>
                <AlertIcon />
                <AlertTitle>{errorsArray.join(', ')}</AlertTitle>
            </Alert>
            )
        }
        <Flex flexDirection={"column"} justifyContent={"center"} alignItems={'center'} gap={5} mt={'70px'}>
            <Image src={decline}  alt='Decline' h={'75px'} w={'75px'}/>
            <Text fontSize={'24px'} fontWeight={'700'} color={'#515151'} lineHeight={'28.13px'}>Decline</Text>
            <Text textAlign={'center'} fontSize={'16px'} fontWeight={'400'} color={'#7A7A7A'} lineHeight={'24px'}>Input Reason Why you want to decline below</Text>
            <Textarea required placeholder='Comment Here' w={['250px','300px', '400px']} borderRadius={'0'} focusBorderColor='white' bg={'#F3F3F3'} border={'none'}
                onChange={(e) => setComment(e.target.value)} value={comment}
            />
            <Button mb={5} w={['250px','300px', '400px']} bg={'#D11A2A'} color={'#FFFFFF'} borderRadius={'3px'} _hover={{ backgroundColor: "" }} _active={{ backgroundColor: "" }}
                fontWeight={"700"} fontSize={'14px'} lineHeight={'17.29px'} isLoading={loading}
                loadingText="Declining ..."  onClick={handleSubmit} 
            >Submit</Button>
        </Flex>
    </Box>
  )
}

export default MembershipDeclineForm;