export const OutstandingDuesData = [
  {
    membershipNo: '10230600A',
    fullName: 'Raphael Treasure',
    phoneNumber: '090456783540',
    outstandingFee: '10,000',
    memberCategory: 'Student',
  },
  {
    membershipNo: '10230600A',
    fullName: 'Raphael Treasure',
    phoneNumber: '090456783540',
    outstandingFee: '10,000',
    memberCategory: 'Student',
  },
  {
    membershipNo: '10230600A',
    fullName: 'Raphael Treasure',
    phoneNumber: '090456783540',
    outstandingFee: '10,000',
    memberCategory: 'Student',
  },
  {
    membershipNo: '10230600A',
    fullName: 'Raphael Treasure',
    phoneNumber: '090456783540',
    outstandingFee: '10,000',
    memberCategory: 'Student',
  },
  {
    membershipNo: '10230600A',
    fullName: 'Raphael Treasure',
    phoneNumber: '090456783540',
    outstandingFee: '10,000',
    memberCategory: 'Student',
  },
  {
    membershipNo: '10230600A',
    fullName: 'Raphael Treasure',
    phoneNumber: '090456783540',
    outstandingFee: '10,000',
    memberCategory: 'Student',
  },
]

export const transactionlogReport = [
  {
    paymentRefNo: '10230600A',
    paymentDate: '12-01-2024',
    transactionAmount: '10,000',
    approvedAmount: '10,000',
    purpose: 'Student Application',
    paymentStatus: 'Successful'
  },
  {
    paymentRefNo: '10230600A',
    paymentDate: '12-01-2024',
    transactionAmount: '10,000',
    approvedAmount: '10,000',
    purpose: 'Student Application',
    paymentStatus: 'Successful'
  },
  {
    paymentRefNo: '10230600A',
    paymentDate: '12-01-2024',
    transactionAmount: '10,000',
    approvedAmount: '10,000',
    purpose: 'Student Application',
    paymentStatus: 'Successful'
  },
  {
    paymentRefNo: '10230600A',
    paymentDate: '12-01-2024',
    transactionAmount: '10,000',
    approvedAmount: '10,000',
    purpose: 'Student Application',
    paymentStatus: 'Successful'
  },
  {
    paymentRefNo: '10230600A',
    paymentDate: '12-01-2024',
    transactionAmount: '10,000',
    approvedAmount: '10,000',
    purpose: 'Student Application',
    paymentStatus: 'Successful'
  },
  {
    paymentRefNo: '10230600A',
    paymentDate: '12-01-2024',
    transactionAmount: '10,000',
    approvedAmount: '10,000',
    purpose: 'Student Application',
    paymentStatus: 'Successful'
  },
]

export const paymentTypeReport = [
  {
    applicantNo: '10230600A',
    fullName: 'Raphael Treasure',
    memberCategory: 'Student',
    emailAddress: 'TJ@gmail.com',
    phoneNumber: '09035973436',
    dateApplied: '12-03-2024',
    paymentStatus: 'Successful',
    qualification: 'Pre-professional',
  },
  {
    applicantNo: '10230600A',
    fullName: 'Raphael Treasure',
    memberCategory: 'Student',
    emailAddress: 'TJ@gmail.com',
    phoneNumber: '09035973436',
    dateApplied: '12-03-2024',
    paymentStatus: 'Successful',
    qualification: 'Pre-professional',
  },
  {
    applicantNo: '10230600A',
    fullName: 'Raphael Treasure',
    memberCategory: 'Student',
    emailAddress: 'TJ@gmail.com',
    phoneNumber: '09035973436',
    dateApplied: '12-03-2024',
    paymentStatus: 'Successful',
    qualification: 'Pre-professional',
  },
  {
    applicantNo: '10230600A',
    fullName: 'Raphael Treasure',
    memberCategory: 'Student',
    emailAddress: 'TJ@gmail.com',
    phoneNumber: '09035973436',
    dateApplied: '12-03-2024',
    paymentStatus: 'Successful',
    qualification: 'Pre-professional',
  },
  {
    applicantNo: '10230600A',
    fullName: 'Raphael Treasure',
    memberCategory: 'Student',
    emailAddress: 'TJ@gmail.com',
    phoneNumber: '09035973436',
    dateApplied: '12-03-2024',
    paymentStatus: 'Successful',
    qualification: 'Pre-professional',
  },
  {
    applicantNo: '10230600A',
    fullName: 'Raphael Treasure',
    memberCategory: 'Student',
    emailAddress: 'TJ@gmail.com',
    phoneNumber: '09035973436',
    dateApplied: '12-03-2024',
    paymentStatus: 'Successful',
    qualification: 'Pre-professional',
  },
]

export const LiveReportData = [
  {
    applicantFullName: 'Raphael Treasure',
    phoneNumber: '09035973436',
    emailAddress: 'tjph@gmail.com',
    dateApplied: '12-03-2024',
    paymentStatus: 'Successful',
  },
  {
    applicantFullName: 'Opeyemi Alabi',
    phoneNumber: '09035973436',
    emailAddress: 'tjph@gmail.com',
    dateApplied: '12-03-2024',
    paymentStatus: 'Successful',
  },
  {
    applicantFullName: 'Tolulope Oyebola',
    phoneNumber: '09035973436',
    emailAddress: 'tjph@gmail.com',
    dateApplied: '12-03-2024',
    paymentStatus: 'Successful',
  },
  {
    applicantFullName: 'Fredrick Chuma',
    phoneNumber: '09035973436',
    emailAddress: 'tjph@gmail.com',
    dateApplied: '12-03-2024',
    paymentStatus: 'Successful',
  },
  {
    applicantFullName: 'Raphael Treasure',
    phoneNumber: '09035973436',
    emailAddress: 'tjph@gmail.com',
    dateApplied: '12-03-2024',
    paymentStatus: 'Successful',
  },
  {
    applicantFullName: 'Raphael Treasure',
    phoneNumber: '09035973436',
    emailAddress: 'tjph@gmail.com',
    dateApplied: '12-03-2024',
    paymentStatus: 'Successful',
  },
]

export const ProgrammeManagementData = [
  {
    programmeName: 'Live Secretary Conference Peformance and pavillon',
    startDate: '06/04/2023',
    endDate: '06/04/2023',
    status: 'On-going',
  },
  {
    programmeName: 'Approval Officer Meeting',
    startDate: '06/04/2023',
    endDate: '06/04/2023',
    status: 'Up-coming',
  },
  {
    programmeName: 'Live Secretary Conference Peformance and pavillon',
    startDate: '06/04/2023',
    endDate: '06/04/2023',
    status: 'Finished',
  },
  {
    programmeName: 'Approval Officer Meeting',
    startDate: '06/04/2023',
    endDate: '06/04/2023',
    status: 'Expired',
  },
]

export const biodataChangeApprovalData = [
  {
    memberName: 'Raphell Treasure',
    memberCategory: 'Student',
    Status: 'Pending',
    age: '27years',
    phoneNumber: '+2348097656743',
    emailAddress: 'rapheltreasure@gmail.com',
    membershipNumber: '1234567',
    membershipGrade: 'Graduate',
  },
  {
    memberName: 'Tolulope Oyebola',
    memberCategory: 'NYSC',
    Status: 'Approved',
    age: '20years',
    phoneNumber: '+2348097656743',
    emailAddress: 'rapheltreasure@gmail.com',
    membershipNumber: '1234567',
    membershipGrade: 'Graduate',
  },
  {
    memberName: 'Gbemileke Daniels ',
    memberCategory: 'Graduate',
    Status: 'Approved',
    age: '25years',
    phoneNumber: '+2348097656743',
    emailAddress: 'rapheltreasure@gmail.com',
    membershipNumber: '1234567',
    membershipGrade: 'Graduate',
  },
  {
    memberName: 'Treasure Ironuma ',
    memberCategory: 'Graduate',
    Status: 'Approved',
    age: '23years',
    phoneNumber: '+2348097656743',
    emailAddress: 'rapheltreasure@gmail.com',
    membershipNumber: '1234567',
    membershipGrade: 'Graduate',
  },
]

export const memberUpgradeDeclinedData = [
  {
    fullName: 'John Oladiran',
    memberNo: '123450000',
    emailAddress: 'JO@gmail.com',
    currentMembership: 'Student',
    ProposedMembership: 'Graduate',
  },
  {
    fullName: 'Raphael Treasure',
    memberNo: '123450000',
    emailAddress: 'JO@gmail.com',
    currentMembership: 'Graduate',
    ProposedMembership: 'Associate',
  },
  {
    fullName: 'Opeyemi Alabi',
    memberNo: '123450000',
    emailAddress: 'JO@gmail.com',
    currentMembership: 'Student',
    ProposedMembership: 'Graduate',
  },
  {
    fullName: 'Frederick Chika',
    memberNo: '123450000',
    emailAddress: 'JO@gmail.com',
    currentMembership: 'Associate',
    ProposedMembership: 'Fellow',
  },
  {
    fullName: 'John Oladiran',
    memberNo: '123450000',
    emailAddress: 'JO@gmail.com',
    currentMembership: 'Nysc',
    ProposedMembership: 'Graduate',
  },
  {
    fullName: 'Raphael Treasure',
    memberNo: '123450000',
    emailAddress: 'JO@gmail.com',
    currentMembership: 'Associate',
    ProposedMembership: 'Fellow',
  },
  {
    fullName: 'Opeyemi Alabi',
    memberNo: '123450000',
    emailAddress: 'JO@gmail.com',
    currentMembership: 'Graduate',
    ProposedMembership: 'Associate',
  },
]

export const memberUpgradeApprovalData = [
  {
    fullName: 'John Oladiran',
    memberNo: '123450000',
    emailAddress: 'JO@gmail.com',
    currentMembership: 'Student',
    ProposedMembership: 'Graduate',
  },
  {
    fullName: 'Raphael Treasure',
    memberNo: '123450000',
    emailAddress: 'JO@gmail.com',
    currentMembership: 'Graduate',
    ProposedMembership: 'Associate',
  },
  {
    fullName: 'Opeyemi Alabi',
    memberNo: '123450000',
    emailAddress: 'JO@gmail.com',
    currentMembership: 'Student',
    ProposedMembership: 'Graduate',
  },
  {
    fullName: 'Frederick Chika',
    memberNo: '123450000',
    emailAddress: 'JO@gmail.com',
    currentMembership: 'Associate',
    ProposedMembership: 'Fellow',
  },
  {
    fullName: 'John Oladiran',
    memberNo: '123450000',
    emailAddress: 'JO@gmail.com',
    currentMembership: 'Nysc',
    ProposedMembership: 'Graduate',
  },
  {
    fullName: 'Raphael Treasure',
    memberNo: '123450000',
    emailAddress: 'JO@gmail.com',
    currentMembership: 'Associate',
    ProposedMembership: 'Fellow',
  },
  {
    fullName: 'Opeyemi Alabi',
    memberNo: '123450000',
    emailAddress: 'JO@gmail.com',
    currentMembership: 'Graduate',
    ProposedMembership: 'Associate',
  },
]

export const memberUpgradeData = [
  {
    fullName: 'John Oladiran',
    memberNo: '123450000',
    emailAddress: 'JO@gmail.com',
    currentMembership: 'Student',
    ProposedMembership: 'Graduate',
  },
  {
    fullName: 'Raphael Treasure',
    memberNo: '123450000',
    emailAddress: 'JO@gmail.com',
    currentMembership: 'Graduate',
    ProposedMembership: 'Associate',
  },
  {
    fullName: 'Opeyemi Alabi',
    memberNo: '123450000',
    emailAddress: 'JO@gmail.com',
    currentMembership: 'Student',
    ProposedMembership: 'Graduate',
  },
  {
    fullName: 'Frederick Chika',
    memberNo: '123450000',
    emailAddress: 'JO@gmail.com',
    currentMembership: 'Associate',
    ProposedMembership: 'Fellow',
  },
  {
    fullName: 'John Oladiran',
    memberNo: '123450000',
    emailAddress: 'JO@gmail.com',
    currentMembership: 'Nysc',
    ProposedMembership: 'Graduate',
  },
  {
    fullName: 'Raphael Treasure',
    memberNo: '123450000',
    emailAddress: 'JO@gmail.com',
    currentMembership: 'Associate',
    ProposedMembership: 'Fellow',
  },
  {
    fullName: 'Opeyemi Alabi',
    memberNo: '123450000',
    emailAddress: 'JO@gmail.com',
    currentMembership: 'Graduate',
    ProposedMembership: 'Associate',
  },
]

export const eventReportTableData = [
  {
    eventType: 'Seminar',
    examSchedule: 'May',
    membershipType: 'Non-Memeber',
    paymentStatus: 'Paid',
  },
  {
    eventType: 'Seminar',
    examSchedule: 'May',
    membershipType: 'Non-Memeber',
    paymentStatus: 'Paid',
  },
  {
    eventType: 'Seminar',
    examSchedule: 'May',
    membershipType: 'Non-Memeber',
    paymentStatus: 'Paid',
  },
  {
    eventType: 'Seminar',
    examSchedule: 'May',
    membershipType: 'Non-Memeber',
    paymentStatus: 'Paid',
  },
  {
    eventType: 'Seminar',
    examSchedule: 'May',
    membershipType: 'Non-Memeber',
    paymentStatus: 'Paid',
  },
  {
    eventType: 'Seminar',
    examSchedule: 'May',
    membershipType: 'Non-Memeber',
    paymentStatus: 'Paid',
  },
]
export const examApplicantReportData = [
  {
    examCentre: 'Lagos',
    examDiet: 'May',
    examType: 'Regular',
    examYear: '2024'
  },
  {
    examCentre: 'Lagos',
    examDiet: 'May',
    examType: 'Regular',
    examYear: '2024'
  },
  {
    examCentre: 'Lagos',
    examDiet: 'May',
    examType: 'Regular',
    examYear: '2024'
  },
  {
    examCentre: 'Lagos',
    examDiet: 'May',
    examType: 'Regular',
    examYear: '2024'
  },
  {
    examCentre: 'Lagos',
    examDiet: 'May',
    examType: 'Regular',
    examYear: '2024'
  },
  {
    examCentre: 'Lagos',
    examDiet: 'May',
    examType: 'Regular',
    examYear: '2024'
  },
]
export const examlistingData = [
  {
    membershipNo: '10230600A',
    fullName: 'Raphael Treasure',
    gender: 'Female',
    examStage: 'Professional',
    grade: 'Applicant',
    examYear: '2024',
  },
  {
    membershipNo: '10230600A',
    fullName: 'Opeyemi Alabi',
    gender: 'Male',
    examStage: 'Professional',
    grade: 'Student',
    examYear: '2024',
  },
  {
    membershipNo: '10230600A',
    fullName: 'Tolulope Oyebola',
    gender: 'Female',
    examStage: 'Professional',
    grade: 'Graduate',
    examYear: '2024',
  },
  {
    membershipNo: '10230600A',
    fullName: 'Fredrick Chuma',
    gender: 'Male',
    examStage: 'Professional',
    grade: 'Associate',
    examYear: '2024',
  },
  {
    membershipNo: '10230600A',
    fullName: 'Raphael Treasure',
    gender: 'Female',
    examStage: 'Professional',
    grade: 'Fellow',
    examYear: '2024',
  },
  {
    membershipNo: '10230600A',
    fullName: 'Raphael Treasure',
    gender: 'Female',
    examStage: 'Professional',
    grade: 'Fellow',
    examYear: '2024',
  },
]

export const departments = [
  {
    departmentName: 'Membership',
    departmentCode: '112',
    deptStatus: 'active',
  },
  {
    departmentName: 'MCPE',
    departmentCode: '101',
    deptStatus: 'active',
  },
  {
    departmentName: 'Subscription',
    departmentCode: '111',
    deptStatus: 'active',
  },
  {
    departmentName: 'NYSC',
    departmentCode: '003',
    deptStatus: 'active',
  },
  {
    departmentName: 'Membership Registration',
    departmentCode: '013',
    deptStatus: 'active',
  },
]

export const registeredStudents = [
  {
    studentName: 'John Oladiran',
    registrationNumber: '2019021522',
    dateRegistered: '20-09-2023',
  },
  {
    studentName: 'Tolulope Oyebola',
    registrationNumber: '2019021522',
    dateRegistered: '20-09-2023',
  },
  {
    studentName: 'John Oladiran',
    registrationNumber: '2019021522',
    dateRegistered: '20-09-2023',
  },
  {
    studentName: 'Tolulope Oyebola',
    registrationNumber: '2019021522',
    dateRegistered: '20-09-2023',
  },
  {
    studentName: 'John Oladiran',
    registrationNumber: '2019021522',
    dateRegistered: '20-09-2023',
  },
  {
    studentName: 'Tolulope Oyebola',
    registrationNumber: '2019021522',
    dateRegistered: '20-09-2023',
  },
]

export const feeItems = [
  {
    feeName: 'Exemption Fee',
    feeFrequency: 'Once',
    feeType: 'Membership Fee',
    feeItemsStatus: 'Active',
    amount: '20,000',
  },
  {
    feeName: 'Annual Dues',
    feeFrequency: 'Annual',
    feeType: 'Membership Fee',
    feeItemsStatus: 'Active',
    amount: '20,000',
  },
  {
    feeName: 'Induction Fee',
    feeFrequency: 'Biennial',
    feeType: 'Subscription fee',
    feeItemsStatus: 'Active',
    amount: '20,000',
  },
  {
    feeName: 'Development Levy',
    feeFrequency: 'Once',
    feeType: 'MCPE',
    feeItemsStatus: 'Active',
    amount: '20,000',
  },
  {
    feeName: 'Election Fee',
    feeFrequency: 'Once',
    feeType: 'Membership Fee',
    feeItemsStatus: 'Active',
    amount: '20,000',
  },
]

export const feeTypeData = [
  {
    feeType: 'Membership Fee',
    feeCode: '112',
    feeStatus: 'Active',
  },
  {
    feeType: 'MCPE',
    feeCode: '101',
    feeStatus: 'Active',
  },
  {
    feeType: 'Subscription Fee',
    feeCode: '111',
    feeStatus: 'Active',
  },
  {
    feeType: 'Subscription Fee',
    feeCode: '003',
    feeStatus: 'Active',
  },
  {
    feeType: 'Membership Registration Fee',
    feeCode: '013',
    feeStatus: 'Active',
  },
]
export const studentReportData = [
  {
    fullName: 'John Oladiran',
    examCategory: 'Pre-Profesional',
    exemptionType: 'Full',
    entryQualificationType: 'Active'
  },
  {
    fullName: 'Fredrick Chuma',
    examCategory: 'Profesional',
    exemptionType: 'Full',
    entryQualificationType: 'Active'
  },
  {
    fullName: 'Tolulope Oyebola',
    examCategory: 'Pre-Profesional',
    exemptionType: 'Contract',
    entryQualificationType: 'Active'
  },
  {
    fullName: 'Raphaell Treasure',
    examCategory: 'Profesional',
    exemptionType: 'Contract',
    entryQualificationType: 'Active'
  },
  {
    fullName: 'Christian Chidi',
    examCategory: 'Profesional',
    exemptionType: 'Full',
    entryQualificationType: 'Active'
  },
  {
    fullName: 'Fredrick Chuma',
    examCategory: 'Profesional',
    exemptionType: 'Full',
    entryQualificationType: 'Active'
  },
  {
    fullName: 'Tolulope Oyebola',
    examCategory: 'Pre-Profesional',
    exemptionType: 'Contract',
    entryQualificationType: 'Active'
  },
  {
    fullName: 'Raphaell Treasure',
    examCategory: 'Profesional',
    exemptionType: 'Contract',
    entryQualificationType: 'Active'
  },
  {
    fullName: 'Christian Chidi',
    examCategory: 'Profesional',
    exemptionType: 'Full',
    entryQualificationType: 'Active'
  },
  {
    fullName: 'Fredrick Chuma',
    examCategory: 'Profesional',
    exemptionType: 'Full',
    entryQualificationType: 'Active'
  },
  {
    fullName: 'Tolulope Oyebola',
    examCategory: 'Pre-Profesional',
    exemptionType: 'Contract',
    entryQualificationType: 'Active'
  },
]

export const memeberManagementDuesData = [
  {
    membershipNo: '10230600A',
    fullName: 'Raphael Treasure',
    memberCategory: 'Female',
    feeDescription: 'NYSC Fee',
    amountPaid: '10,000',
    amountOwed: '10,000',
  },
  {
    membershipNo: '10230600A',
    fullName: 'Opeyemi Alabi',
    memberCategory: 'Male',
    feeDescription: 'Membership Fee',
    amountPaid: '10,000',
    amountOwed: '10,000',
  },
  {
    membershipNo: '10230600A',
    fullName: 'Tolulope Oyebola',
    memberCategory: 'Female',
    feeDescription: 'Subscription Fee',
    amountPaid: '10,000',
    amountOwed: '10,000',
  },
  {
    membershipNo: '10230600A',
    fullName: 'Fedrick Chuma',
    memberCategory: 'Male',
    feeDescription: 'NYSC Fee',
    amountPaid: '10,000',
    amountOwed: '10,000',
  },
  {
    membershipNo: '10230600A',
    fullName: 'Raphael Treasure',
    memberCategory: 'Female',
    feeDescription: 'NYSC Fee',
    amountPaid: '10,000',
    amountOwed: '10,000',
  },
  {
    membershipNo: '10230600A',
    fullName: 'Raphael Treasure',
    memberCategory: 'Female',
    feeDescription: 'NYSC Fee',
    amountPaid: '10,000',
    amountOwed: '10,000',
  },
]

export const memberManagementData = [
  {
    membershipNo: '10230600A',
    fullName: 'Raphael Treasure',
    gender: 'Female',
    qualification: 'Professional',
    memberCategory: 'Associate'
  },
  {
    membershipNo: '10230600A',
    fullName: 'Opeyemi Alabi',
    gender: 'Male',
    qualification: 'Professional',
    memberCategory: 'Associate'
  },
  {
    membershipNo: '10230600A',
    fullName: 'Tolulope Oyebola',
    gender: 'Female',
    qualification: 'Professional',
    memberCategory: 'Associate'
  },
  {
    membershipNo: '10230600A',
    fullName: 'Fredrick Chuma',
    gender: 'Male',
    qualification: 'Professional',
    memberCategory: 'Associate'
  },
  {
    membershipNo: '10230600A',
    fullName: 'Raphael Treasure',
    gender: 'Female',
    qualification: 'Professional',
    memberCategory: 'Associate'
  },
  {
    membershipNo: '10230600A',
    fullName: 'Raphael Treasure',
    gender: 'Female',
    qualification: 'Professional',
    memberCategory: 'Associate'
  },
]

export const memberReportData = [
  {
    membershipNo: '10230600A',
    fullName: 'Raphael Treasure',
    gender: 'Female',
    qualification: 'Professional',
    qualificationType: 'Active'
  },
  {
    membershipNo: '10230600A',
    fullName: 'Opeyemi Alabi',
    gender: 'Male',
    qualification: 'Professional',
    qualificationType: 'Active'
  },
  {
    membershipNo: '10230600A',
    fullName: 'Tolulope Oyebola',
    gender: 'Female',
    qualification: 'Professional',
    qualificationType: 'Active'
  },
  {
    membershipNo: '10230600A',
    fullName: 'Fredrick Chuma',
    gender: 'Male',
    qualification: 'Professional',
    qualificationType: 'Active'
  },
  {
    membershipNo: '10230600A',
    fullName: 'Raphael Treasure',
    gender: 'Female',
    qualification: 'Professional',
    qualificationType: 'Active'
  },
  {
    membershipNo: '10230600A',
    fullName: 'Raphael Treasure',
    gender: 'Female',
    qualification: 'Professional',
    qualificationType: 'Active'
  },
]
export const ExamCenterReportData = [
  {
    centreName: 'Lagos',
    centreCode: 'LAG',
    centreStatus: 'Active'
  },
  {
    centreName: 'Abuja',
    centreCode: 'ABJ',
    centreStatus: 'Active'
  },
  {
    centreName: 'Rivers',
    centreCode: 'RIV',
    centreStatus: 'Active'
  },
  {
    centreName: 'Kaduna',
    centreCode: 'KAD',
    centreStatus: 'Active'
  },
  {
    centreName: 'Enugu',
    centreCode: 'ENU',
    centreStatus: 'Active'
  },
  {
    centreName: 'Calabar',
    centreCode: 'CAL',
    centreStatus: 'Active'
  },
]

export const inductionData = [
  {
    fullName: 'John Oladiran',
    memberCategory: 'Graduate',
    dateRegistered: '01-10-2023'
  },
  {
    fullName: 'Fredrick Chuma',
    memberCategory: 'Associate',
    dateRegistered: '04-10-2023'
  },
  {
    fullName: 'Tolulope Oyebola',
    memberCategory: 'Fellow',
    dateRegistered: '06-10-2023'
  },
  {
    fullName: 'Raphaell Treasure',
    memberCategory: 'Fellow',
    dateRegistered: '10-10-2023'
  },
  {
    fullName: 'Christian Chidi',
    memberCategory: 'Associate',
    dateRegistered: '12-10-2023'
  },
  {
    fullName: 'Fredrick Chuma',
    memberCategory: 'Graduate',
    dateRegistered: '20-10-2023'
  },
  {
    fullName: 'Tolulope Oyebola',
    memberCategory: 'Graduate',
    dateRegistered: '23-10-2023'
  },
  {
    fullName: 'Raphaell Treasure',
    memberCategory: 'Associate',
    dateRegistered: '23-10-2023'
  },
  {
    fullName: 'Christian Chidi',
    memberCategory: 'Associate',
    dateRegistered: '23-10-2023'
  },
  {
    fullName: 'Fredrick Chuma',
    memberCategory: 'Fellow',
    dateRegistered: '23-10-2023'
  },
  {
    fullName: 'Tolulope Oyebola',
    memberCategory: 'Graduate',
    dateRegistered: '23-10-2023'
  },
]

export const cardReportData = [
  {
    memberName: 'John Oladiran',
    regNumber: '21000001',
    paymentDate: '01-10-2023',
    amountPaid: '10,000',
    feeName: 'Annual Dues',
    email: 'Johno@gmail.com',
    phoneNumber: '08068698365',
    dob: '10/10/1999',
  },
  {
    memberName: 'Fredrick Chuma',
    regNumber: '21000012',
    paymentDate: '04-10-2023 ',
    amountPaid: '10,000',
    feeName: 'Annual Dues',
    email: 'Johno@gmail.com',
    phoneNumber: '08068698365',
    dob: '10/10/1999',
  },
  {
    memberName: 'Tolulope Oyebola',
    regNumber: '21000013',
    paymentDate: '06-10-2023',
    amountPaid: '10,000',
    feeName: 'Annual Dues',
    email: 'Johno@gmail.com',
    phoneNumber: '08068698365',
    dob: '10/10/1999',
  },
  {
    memberName: 'Raphaell Treasure',
    regNumber: '21000234',
    paymentDate: '10-10-2023',
    amountPaid: '10,000',
    feeName: 'Annual Dues',
    email: 'Johno@gmail.com',
    phoneNumber: '08068698365',
    dob: '10/10/1999',
  },
  {
    memberName: 'Christian Chidi',
    regNumber: '21000248',
    paymentDate: '12-10-2023',
    amountPaid: '10,000',
    feeName: 'Annual Dues',
    email: 'Johno@gmail.com',
    phoneNumber: '08068698365',
    dob: '10/10/1999',
  },
  {
    memberName: 'Fredrick Chuma',
    regNumber: '21000249',
    paymentDate: '20-10-2023',
    amountPaid: '10,000',
    feeName: 'Annual Dues',
    email: 'Johno@gmail.com',
    phoneNumber: '08068698365',
    dob: '10/10/1999',
  },
  {
    memberName: 'Tolulope Oyebola',
    regNumber: '21000256',
    paymentDate: '23-10-2023',
    amountPaid: '10,000',
    feeName: 'Annual Dues',
    email: 'Johno@gmail.com',
    phoneNumber: '08068698365',
    dob: '10/10/1999',
  },
  {
    memberName: 'Raphaell Treasure',
    regNumber: '21000289',
    paymentDate: '23-10-2023',
    amountPaid: '10,000',
    feeName: 'Annual Dues',
    email: 'Johno@gmail.com',
    phoneNumber: '08068698365',
    dob: '10/10/1999',
  },
  {
    memberName: 'Christian Chidi',
    regNumber: '21000290',
    paymentDate: '23-10-2023',
    amountPaid: '10,000',
    feeName: 'Annual Dues',
    email: 'Johno@gmail.com',
    phoneNumber: '08068698365',
    dob: '10/10/1999',
  },
  {
    memberName: 'Fredrick Chuma',
    regNumber: '21000295',
    paymentDate: '23-10-2023',
    amountPaid: '10,000',
    feeName: 'Annual Dues',
    email: 'Johno@gmail.com',
    phoneNumber: '08068698365',
    dob: '10/10/1999',
  },
  {
    memberName: 'Tolulope Oyebola',
    regNumber: '21000297',
    paymentDate: '23-10-2023',
    amountPaid: '10,000',
    feeName: 'Annual Dues',
    email: 'Johno@gmail.com',
    phoneNumber: '08068698365',
    dob: '10/10/1999',
  },
]

export const birthdayData = [
  {
    memberName: 'Fredrick Chukwuma',
    birthday: '27th April',
    birthdayStatus: 'sent'
  },
  {
    memberName: 'Tolulope Oyebola',
    birthday: '18th October',
    birthdayStatus: 'sent'
  },
  {
    memberName: 'Raphael Treasure',
    birthday: '25th December',
    birthdayStatus: 'Pending'
  },
  {
    memberName: 'Fredrick Chukwuma',
    birthday: '13th January',
    birthdayStatus: 'Failed'
  },
  {
    memberName: 'Tolulope Oyebola',
    birthday: '14th February',
    birthdayStatus: 'sent'
  },
  {
    memberName: 'Raphael Treasure',
    birthday: '30th September',
    birthdayStatus: 'sent'
  },
]

export const settingsData = [
  {
    approvalName: 'Student Approval',
    approvalOrder: '1',
    dateCreated: '06-08-2023',
    memebershipGrade: 'NYSC'
  },
  {
    approvalName: 'Student Approval',
    approvalOrder: '2',
    dateCreated: '06-08-2023',
    memebershipGrade: 'Student'
  },
  {
    approvalName: 'Member Upgrade',
    approvalOrder: '3',
    dateCreated: '06-08-2023',
    memebershipGrade: 'Fellow'
  },
  {
    approvalName: 'Member Upgrade',
    approvalOrder: '3',
    dateCreated: '06-08-2023',
    memebershipGrade: 'Member'
  },
  {
    approvalName: 'Member Upgrade',
    approvalOrder: '3',
    dateCreated: '06-08-2023',
    memebershipGrade: 'NYSC'
  },
]

export const workflowData = [
  {
    approvalName: 'Student Approval',
    approvalLevel: 'Level 1',
    roleAssigned: 'Clerk',
    dateCreated: '06-08-2023',
    memebershipGrade: 'NYSC'
  },
  {
    approvalName: 'Student Approval',
    approvalLevel: 'Level 2',
    roleAssigned: 'Register',
    dateCreated: '06-08-2023',
    memebershipGrade: 'Student'
  },
  {
    approvalName: 'Member Upgrade',
    approvalLevel: 'Department',
    roleAssigned: 'Approval Officer',
    dateCreated: '06-08-2023',
    memebershipGrade: 'Fellow'
  },
  {
    approvalName: 'Member Upgrade',
    approvalLevel: 'Comitee',
    roleAssigned: 'Clerk',
    dateCreated: '06-08-2023',
    memebershipGrade: 'Member'
  },
  {
    approvalName: 'Member Upgrade',
    approvalLevel: 'Council',
    roleAssigned: 'Clerk',
    dateCreated: '06-08-2023',
    memebershipGrade: 'NYSC'
  },
]

export const membershipData = [
  {
    memberName: 'John Oladiran',
    memberCategory: 'Student',
    approvedStage: 'Level 1',
    memberStatus: 'Approved'
  },
  {
    memberName: 'Raphael Treasure',
    memberCategory: 'Fellow',
    approvedStage: 'Level 1',
    memberStatus: 'Declined'
  },
  {
    memberName: 'Opeyemi Alabi',
    memberCategory: 'Fellow',
    approvedStage: 'Level 2',
    memberStatus: 'Pending'
  },
  {
    memberName: 'Frederick Chika',
    memberCategory: 'Associate',
    approvedStage: 'Level 1',
    memberStatus: 'Declined'
  },
  {
    memberName: 'John Oladiran',
    memberCategory: 'NYSC',
    approvedStage: 'Level 2',
    memberStatus: 'Approved'
  },
  {
    memberName: 'Raphael Treasure',
    memberCategory: 'Student',
    approvedStage: 'Level 2',
    memberStatus: 'Declined'
  },
]

export const upgradeData = [
  {
    memberName: 'John Oladiran',
    currentMembership: 'Student',
    proposedUpgrade: 'Associate',
    applicationNumber: '30267845678',
    memberStatus: 'In Progress',
    email: 'john@gmail.com',
    phoneNumber: '09035973436',
    dateRegistered: '03-09-2023',

  },
  {
    memberName: 'Raphael Treasure',
    currentMembership: 'Student',
    proposedUpgrade: 'Fellow',
    applicationNumber: '30267845678',
    memberStatus: 'Pending'
  },
  {
    memberName: 'Opeyemi Alabi',
    currentMembership: 'Associate',
    proposedUpgrade: 'Fellow',
    applicationNumber: '30267845678',
    memberStatus: 'Approved'
  },
  {
    memberName: 'Frederick Chika',
    currentMembership: 'NYSC',
    proposedUpgrade: 'Student',
    applicationNumber: '30267845678',
    memberStatus: 'Declined'
  },
  {
    memberName: 'John Oladiran',
    currentMembership: 'Fellow',
    proposedUpgrade: 'Associate',
    applicationNumber: '30267845678',
    memberStatus: 'In Progress'
  },
  {
    memberName: 'Raphael Treasure',
    currentMembership: 'Fellow',
    proposedUpgrade: 'Associate',
    applicationNumber: '30267845678',
    memberStatus: 'Pending'
  },
  {
    memberName: 'Opeyemi Alabi',
    currentMembership: 'Student',
    proposedUpgrade: 'Associate',
    applicationNumber: '30267845678',
    memberStatus: 'Approved'
  },
  {
    memberName: 'Frederick Chika',
    currentMembership: 'Student',
    proposedUpgrade: 'Associate',
    applicationNumber: '30267845678',
    memberStatus: 'Declined'
  },
]
export const memberUpgrade = [
  {
    date: "23/04/24",
    currentGrade: "Student",
    nextUpgrade: "Graduate",
    membershipUpgradeStatus: "Rejected",
    action: "Re-apply",
    payment: "Unpaid"
  },
  {
    date: "10/12/23",
    currentGrade: "NYSC",
    nextUpgrade: "Student",
    membershipUpgradeStatus: "Approved",
    action: "Proceed to pay",
    payment: "Paid"
  },
];

export const examCentreChangeData = [
  {
    fullName: "John Doe",
    applicationNumber: "123456",
    email: "johndoe@example.com",
    dateRegistered: "2023-08-01",
  },
  {
    fullName: "Jane Smith",
    applicationNumber: "789012",
    email: "janesmith@example.com",
    dateRegistered: "2023-08-10",
  },
];


export const eventsData = [
  {
    serialNumber: "1",
    eventName: "Live Secretary Conference-Peformance and pavillon",
    startDate: "06/04/2023",
    endDate: "06/04/2023",
    statusUpdate: "ongoing",
    venue: "Plot 24, Agungi Road, Lekki-Ajah, Lagos State",
    creditPoints: "5",
  },
  {
    serialNumber: "2",
    eventName: "Approval Officer Meeting",
    startDate: "06/04/2023",
    endDate: "06/04/2023",
    statusUpdate: "upcoming",
    venue: "Plot 24, Agungi Road, Lekki-Ajah, Lagos State",
    creditPoints: "5",
  },
  {
    serialNumber: "3",
    eventName: "Live Secretary Conference-Peformance and pavillon",
    startDate: "06/04/2023",
    endDate: "06/04/2023",
    statusUpdate: "Finished",
    venue: "Plot 24, Agungi Road, Lekki-Ajah, Lagos State",
    creditPoints: "5",
  },
  {
    serialNumber: "4",
    eventName: "Approval Officer Meeting",
    startDate: "06/04/2023",
    endDate: "06/04/2023",
    statusUpdate: "Expired",
    venue: "Plot 24, Agungi Road, Lekki-Ajah, Lagos State",
    creditPoints: "5",
  },
];

export const users = [
  {
    firstName: "Clementina ",
    lastName: "Akpojaro",
    email: "admin@icsan.com",
    phoneNumber: "08067897865",
    type: "Admin",
    role: "Admin",
    status: "Active",
    profileImg: "",
  },
  {
    firstName: "Sylvanus",
    lastName: "Tahir",
    email: "officer@icsan.com",
    phoneNumber: "09087651245",
    role: "Officer",
    type: "Officer",
    status: "Active",
  },
];

export const upcomingExams = [
  {
    id: 1,
    date: "20th July ",
    time: "10:00 AM",
    title: "The ICSAN Seminar",
    attendee: "50 Attendees",
  },
  {
    id: 2,
    date: "20th July ",
    time: "11:00 AM",
    title: "Event Pavillion Health Talk",
    attendee: "150 Attendees",
  },
  {
    id: 3,
    date: "10th July ",
    time: "9:00 AM",
    title: "Introduction to accountancy",
    attendee: "Theory",
  },
];

export const paymentSummary = [
  {
    id: 1,
    date: "21-08-2023",
    subscriptionType: "Premium Plan",
    amount: "34,000",
    status: "successful"
  },
  {
    id: 2,
    date: "21-08-2023",
    subscriptionType: "Premium Plan",
    amount: "30,000",
    status: "unsuccessful"
  },
  {
    id: 3,
    date: "21-08-2023",
    subscriptionType: "Premium Plan",
    amount: "34,000",
    status: "successful"
  },


];


export const barChartData = [
  {
    name: "Jan",
    accepted: "200",
    rejected: "300",
  },
  {
    name: "Feb",
    accepted: "350",
    rejected: "400",
  },
  {
    name: "Mar",
    accepted: "600",
    rejected: "300",
  },
  {
    name: "Apr",
    accepted: "400",
    rejected: "500",
  },
  {
    name: "May",
    accepted: "600",
    rejected: "300",
  },
  {
    name: "Jun",
    accepted: "600",
    rejected: "300",
  },
  {
    name: "Jul",
    accepted: "600",
    rejected: "300",
  },
  {
    name: "Aug",
    accepted: "200",
    rejected: "300",
  },
  {
    name: "Sep",
    accepted: "350",
    rejected: "400",
  },
  {
    name: "Oct",
    accepted: "600",
    rejected: "350",
  },
  {
    name: "Nov",
    accepted: "400",
    rejected: "500",
  },
  {
    name: "Dec",
    accepted: "600",
    rejected: "350",
  },

];

export const eduBarChartData = [
  {
    name: "Jan",
    approved: "200",
    inProgess: '300',
    pending: '400',
    rejected: "70",

  },
  {
    name: "Feb",
    approved: "200",
    inProgess: '300',
    pending: '400',
    rejected: "70",

  },
  {
    name: "Mar",
    approved: "200",
    inProgess: '300',
    pending: '400',
    rejected: "70",

  },
  {
    name: "Apr",
    approved: "200",
    inProgess: '300',
    pending: '400',
    rejected: "70",

  },
  {
    name: "May",
    approved: "200",
    inProgess: '300',
    pending: '400',
    rejected: "70",

  },
  {
    name: "Jun",
    approved: "200",
    inProgess: '300',
    pending: '400',
    rejected: "70",

  },
  {
    name: "Jul",
    approved: "200",
    inProgess: '300',
    pending: '400',
    rejected: "70",

  },
  // {
  //   name: "Aug",
  //   approved: "200",
  //   inProgess: '300',
  //   pending: '400',
  //   rejected: "70",

  // },
  // {
  //   name: "Sep",
  //   approved: "200",
  //   inProgess: '300',
  //   pending: '400',
  //   rejected: "70",

  // },
  // {
  //   name: "Oct",
  //   approved: "200",
  //   inProgess: '300',
  //   pending: '400',
  //   rejected: "70",

  // },
  // {
  //   name: "Nov",
  //   approved: "200",
  //   inProgess: '300',
  //   pending: '400',
  //   rejected: "70",

  // },
  // {
  //   name: "Dec",
  //   approved: "200",
  //   inProgess: '300',
  //   pending: '400',
  //   rejected: "70",

  // },

];


export const pieChartData = [
  {
    name: "Maths 101",
    value: 200,
  },
  {
    name: "Law 221 ",
    value: 360,
  },
  {
    name: "Psy 231",
    value: 140,
  },

];

export const membershipPieChartData = [
  {
    name: "Student",
    value: 200,
  },
  {
    name: "Graduate ",
    value: 360,
  },
  {
    name: "NYSC",
    value: 140,
  },
  {
    name: "Psy 231",
    value: 120,
  },

];
export const financePieChartData = [
  {
    name: "Student",
    value: 200,
  },
  {
    name: "Graduate ",
    value: 360,
  },
  {
    name: "NYSC",
    value: 140,
  },
  {
    name: "Fellow",
    value: 120,
  },

];

export const pieChartData2 = [
  {
    name: "Regular",
    value: 100,
  },
  {
    name: "Resit",
    value: 50,
  },


];

export const lineChartData = [
  {
    name: "Jan",
    value: 10000,
  },
  {
    name: "Feb",
    value: 50000,
  },
  {
    name: "Mar",
    value: 60000,
  },
  {
    name: "Apr",
    value: 50000,
  },
  {
    name: "May",
    value: 40000,
  },
  {
    name: "Jun",
    value: 50000,
  },
  {
    name: "Jul",
    value: 90000,
  },

];


export const userData = [
  {
    staffId: "#1",
    fullName: "Clementina Akpojaro",
    email: "Clementina@gmail.com",
    role: "Admin",
    department: "Technology",
    status: "Active",
  },
  {
    staffId: "#2",
    fullName: "John Oladiran",
    email: "John@gmail.com",
    role: "Approval Officer",
    department: "Technology",
    status: "Active",
  },
  {
    staffId: "#3",
    fullName: "Jane Hood",
    email: "Jane@gmail.com",
    role: "Member",
    department: "Technology",
    status: "Active",
  },

];

export const RoleData = [
  {
    name: "Admin",
    description: "Admin",
    users: 10,
    status: "Approved"
  },
  {
    name: "Approval Officer",
    description: "Approval Officer",
    users: 15,
    status: "Disabled",
  },
  {
    name: "Member",
    description: "Member",
    users: 30,
    status: "Pending",
  },
  {
    name: "Student",
    description: "Student",
    users: 100,
    status: "Approved",
  },
]

export const examDietData = [
  {
    diet: "January",
    status: "Active",
  },
  {
    diet: "March",
    status: "Active",
  },
  {
    diet: "May",
    status: "Active",
  },
  {
    diet: "June",
    status: "Active",
  },
  {
    diet: "August",
    status: "Active",
  },
  {
    diet: "September",
    status: "Active",
  },
]

export const examPeriodData = [
  {
    examYear: "2023",
    startDate: "10-09-2023",
    endDate: "01-01-2024",
  },
  {
    examYear: "2023",
    startDate: "10-09-2023",
    endDate: "01-01-2024",
  },
  {
    examYear: "2023",
    startDate: "10-09-2023",
    endDate: "01-01-2024",
  },
  {
    examYear: "2023",
    startDate: "10-09-2023",
    endDate: "01-01-2024",
  },
]

export const examCategoryData = [
  {
    categoryName: "Degree Programme",
    categoryCode: "DEG101",
    status: "Active",
  },
  {
    categoryName: "Pre-professional",
    categoryCode: "PP201",
    status: "Active",
  },
  {
    categoryName: "Professional",
    categoryCode: "P101",
    status: "Active",
  },
]

export const examSubjectData = [
  {
    subjectName: "Law",
    categoryCode: "DEG101",
    categoryName: "Degree Programme",
  },
  {
    subjectName: "Economics",
    categoryCode: "PP201",
    categoryName: "Pre-professional",
  },
  {
    subjectName: "Business Administration",
    categoryCode: "P101",
    categoryName: "Professional",
  },
]

export const examCentreData = [
  {
    centreName: "Lagos",
    centreCode: "LAG",
    address: "Centre of Secretaries and development Surulere, Lagos.",
  },
  {
    centreName: "Abuja",
    centreCode: "ABJ",
    address: "Centre of Secretaries and development Surulere, Lagos.",
  },
  {
    centreName: "Kaduna",
    centreCode: "KAD",
    address: "Centre of Secretaries and development Surulere, Lagos.",
  },
  {
    centreName: "Portharcourt",
    centreCode: "PHC",
    address: "Centre of Secretaries and development Surulere, Lagos.",
  },
  {
    centreName: "Enugu",
    centreCode: "ENG",
    address: "Centre of Secretaries and development Surulere, Lagos.",
  },
]

export const uploadResultData = [
  {
    examYear: '2023',
    examDiet: 'December',
    category: 'Pre-Professional',
    examType: 'Regular',
  },
  {
    examYear: '2023',
    examDiet: 'June',
    category: 'Professional',
    examType: 'Resit',
  },
  {
    examYear: '2023',
    examDiet: 'December',
    category: 'Pre-Professional',
    examType: 'Regular',
  },
  {
    examYear: '2023',
    examDiet: 'June',
    category: 'Professional',
    examType: 'Resit',

  },
]

export const loginActData = [
  {
    activity: 'First Login To Site',
    time: 'Monday,12 October 2023  7:30Am',
  },
  {
    activity: 'Recent Login To Site',
    time: 'Tuesday,13 October 2023  12:00Pm',
  },
]


export const permissionRoleData = [
  {
    name: "Dashboard",
    submenu: [
      { name: "Cards" },
      { name: "UpcomingEvent" },
      { name: "LineChart" },
      { name: "PieChart" },
      { name: "BarChart" },
    ],
  },
  {
    name: "User Management",
    submenu: [
      { name: "Roles" },
      { name: "Users" },
    ],
  },
  {
    name: "Event Management",
    submenu: [
      { name: "Events" },
    ],
  },
  {
    name: "Fee Management",
    submenu: [
      { name: "Fee Type" },
      { name: "Fee Items" },
    ],
  },
  {
    name: "Approval",
    submenu: [
      { name: "Approval Settings" },
      { name: "Approval Workflow" },
    ],
  },
  {
    name: "Department Management",
    submenu: [
      { name: "Departments" },
    ],
  },
  {
    name: "Report",
    submenu: [
      { name: "Approval Report" },
      { name: "Student Report" },
      { name: "Member Report" },
      { name: "Exam Center Report" },
      { name: "Induction Report" },
      { name: "E-Membership Card Report" },
      { name: "Birthday Report" },
    ],
  },
  {
    name: "Exam Setup",
    submenu: [
      { name: "Exam Diet" },
      { name: "Exam Categories" },
      { name: "Exam Subjects" },
      { name: "Exam Centers" },
      { name: "Exam Timetable" },
      { name: "Exam Result" },
    ],
  },

];

export const result = [
  {
    "module": "Dashboard",
    "resource": "CounterChart",
    "action": "View",
    "value": "Granted"
  },
  {
    "module": "Dashboard",
    "resource": "MembershipApprovalChart",
    "action": "View",
    "value": "Granted"
  },
  {
    "module": "Dashboard",
    "resource": "TotalMemberChart",
    "action": "View",
    "value": "Granted"
  },
  {
    "module": "Dashboard",
    "resource": "UpcomingExams",
    "action": "View",
    "value": "Granted"
  },
  {
    "module": "Dashboard",
    "resource": "SubscriptionPaymentSummary",
    "action": "View",
    "value": "Granted"
  },
  {
    "module": "User Management",
    "resource": "Users",
    "action": "View",
    "value": "Granted"
  },
  {
    "module": "User Management",
    "resource": "Users",
    "action": "Create",
    "value": "Granted"
  },
  {
    "module": "User Management",
    "resource": "Users",
    "action": "Edit",
    "value": "Granted"
  },
  {
    "module": "User Management",
    "resource": "Roles",
    "action": "View",
    "value": "Granted"
  },
  {
    "module": "User Management",
    "resource": "Roles",
    "action": "Create",
    "value": "Granted"
  },
  {
    "module": "User Management",
    "resource": "Roles",
    "action": "Edit",
    "value": "Granted"
  },
  {
    "module": "Event Management",
    "resource": "Events",
    "action": "View",
    "value": "Granted"
  },
  {
    "module": "Event Management",
    "resource": "Events",
    "action": "Create",
    "value": "Granted"
  },
  {
    "module": "Event Management",
    "resource": "Events",
    "action": "Edit",
    "value": "Granted"
  },
  {
    "module": "Approval",
    "resource": "Approval Settings",
    "action": "View",
    "value": "Granted"
  },
  {
    "module": "Approval",
    "resource": "Approval Settings",
    "action": "Edit",
    "value": "Granted"
  },
  {
    "module": "Approval",
    "resource": "Approval Workflow",
    "action": "Edit",
    "value": "Granted"
  },
  {
    "module": "Approval",
    "resource": "Approval Workflow",
    "action": "View",
    "value": "Granted"
  },
  {
    "module": "Fee Management",
    "resource": "Fee Type",
    "action": "View",
    "value": "Granted"
  },
  {
    "module": "Fee Management",
    "resource": "Fee Items",
    "action": "View",
    "value": "Granted"
  },
  {
    "module": "Department Management",
    "resource": "Department",
    "action": "View",
    "value": "Granted"
  },
  {
    "module": "Exam Setup",
    "resource": "exam Diet",
    "action": "View",
    "value": "Granted"
  },
  {
    "module": "Exam Setup",
    "resource": "exam Categories",
    "action": "View",
    "value": "Granted"
  },
  {
    "module": "Exam Setup",
    "resource": "exam Centers",
    "action": "View",
    "value": "Granted"
  },
  {
    "module": "Exam Setup",
    "resource": "exam Timetable",
    "action": "View",
    "value": "Granted"
  },
  {
    "module": "Exam Setup",
    "resource": "exam Result",
    "action": "View",
    "value": "Granted"
  },
  {
    "module": "Exam Setup",
    "resource": "EducationExam Result",
    "action": "View",
    "value": "Granted"
  },
  {
    "module": "Report",
    "resource": "approval Report",
    "action": "View",
    "value": "Granted"
  },
  {
    "module": "Report",
    "resource": "Member Report",
    "action": "View",
    "value": "Granted"
  },
  {
    "module": "Report",
    "resource": "Member Management Report",
    "action": "View",
    "value": "Granted"
  },
  {
    "module": "Report",
    "resource": "Exam Center Report",
    "action": "View",
    "value": "Granted"
  },
  {
    "module": "Report",
    "resource": "Induction Report",
    "action": "View",
    "value": "Granted"
  },
  {
    "module": "Report",
    "resource": "E Membership Card Report",
    "action": "View",
    "value": "Granted"
  },
  {
    "module": "Report",
    "resource": "Birthday Report",
    "action": "View",
    "value": "Granted"
  },
  {
    "module": "Report",
    "resource": "Event Report",
    "action": "View",
    "value": "Granted"
  },
  {
    "module": "Report",
    "resource": "Exam Applicant Report",
    "action": "View",
    "value": "Granted"
  },
  {
    "module": "Report",
    "resource": "Exam Result Listing Report",
    "action": "View",
    "value": "Granted"
  },
  {
    "module": "Report",
    "resource": "Member Dues Management",
    "action": "View",
    "value": "Granted"
  },

  {
    "module": "Member Management",
    "resource": "Update Biodata",
    "action": "View",
    "value": "Granted"
  },
  {
    "module": "Member Management",
    "resource": "Member Upgrade",
    "action": "View",
    "value": "Granted"
  },
  {
    "module": "Member Management",
    "resource": "Update Biodata Change Approval",
    "action": "View",
    "value": "Granted"
  },
  {
    "module": "Application Approval",
    "resource": "Student",
    "action": "View",
    "value": "Granted"
  },
  {
    "module": "Application Approval",
    "resource": "NYSC",
    "action": "View",
    "value": "Granted"
  },
  {
    "module": "Application Approval",
    "resource": "Associate",
    "action": "View",
    "value": "Granted"
  },
  {
    "module": "Application Approval",
    "resource": "Fellow",
    "action": "View",
    "value": "Granted"
  },
  {
    "module": "Statement of Result",
    "resource": "Result",
    "action": "View",
    "value": "Granted"
  },
];

export const studentData = [
  {
    fullName: "John Oladiran",
    appNo: "123456789",
    email: "johnoladiran@gmail.com",
    dateRegistered: "12-12-2023",
  },
  {
    fullName: "Raphael Treasure",
    appNo: "123456789",
    email: "johnoladiran@gmail.com",
    dateRegistered: "12-12-2023",
  },
  {
    fullName: "John Oladiran",
    appNo: "123456789",
    email: "johnoladiran@gmail.com",
    dateRegistered: "12-12-2023",
  },
  {
    fullName: "Raphael Treasure",
    appNo: "123456789",
    email: "johnoladiran@gmail.com",
    dateRegistered: "12-12-2023",
  },
]

export const NYSCData = [
  {
    fullName: "John Oladiran",
    appNo: "123456789",
    email: "johnoladiran@gmail.com",
    dateRegistered: "12-12-2023",
  },
  {
    fullName: "Raphael Treasure",
    appNo: "123456789",
    email: "johnoladiran@gmail.com",
    dateRegistered: "12-12-2023",
  },
  {
    fullName: "John Oladiran",
    appNo: "123456789",
    email: "johnoladiran@gmail.com",
    dateRegistered: "12-12-2023",
  },
  {
    fullName: "Raphael Treasure",
    appNo: "123456789",
    email: "johnoladiran@gmail.com",
    dateRegistered: "12-12-2023",
  },
]

export const EducationExamResultData = [
  {
    category: "Pre-Professional",
  },
  {
    category: "Foundation",
  },
  {
    category: "Professional Programme 1",
  },
  {
    category: "Professional Programme 2",
  },

];

export const EduExamResultDetailsData = [
  {
    studentNo: "6798444",
    examNo: "ABJ 001",
    subjectCode: "F1/001",
    grade: "pass",
  },
  {
    studentNo: "6798445",
    examNo: "ABJ 002",
    subjectCode: "F1/001",
    grade: "distinction",
  },
  {
    studentNo: "6798444",
    examNo: "ABJ 001",
    subjectCode: "F1/001",
    grade: "pass",
  },
  {
    studentNo: "6798445",
    examNo: "ABJ 002",
    subjectCode: "F1/001",
    grade: "distinction",
  },
];

export const studentdashTableData = [
  {
    feeName: 'Exemption Fee',
    year: "2022",
    amountOwed: "10000"
  },
  {
    feeName: 'Annual Fee',
    year: "2023",
    amountOwed: "15000"
  },
  {
    feeName: 'Registration Fee',
    year: "2024",
    amountOwed: "15000"
  },
  {
    feeName: 'Exemption Fee',
    year: "2022",
    amountOwed: "10000"
  },
  {
    feeName: 'Annual Fee',
    year: "2023",
    amountOwed: "15000"
  },
  {
    feeName: 'Registration Fee',
    year: "2024",
    amountOwed: "15000"
  },
]

export const upcomingStudentEvent = [
  {
    id: 1,
    date: "20th July 2023 ",
    time: "10:00 AM",
    title: "NYSC - STUDENT",
    attendee: "Upgrade successful and approved",
  },
  {
    id: 2,
    date: "20th July ",
    time: "11:00 AM",
    title: "STUDENT - GRADUATE",
    attendee: "Upgrade Pending",
  },
  {
    id: 3,
    date: "10th July ",
    time: "9:00 AM",
    title: "STUDENT - GRADUATE",
    attendee: "Upgrade Pending",
  },
];

export const memberPaymentHistoryData = [
  {
    paymentREFNO: "10230600A",
    paymentDate: "02/05/2017",
    transactionAmount: "20,000",
    purpose: "Examination Application",
    status: "Successful"
  },
  {
    paymentREFNO: "10230600A",
    paymentDate: "02/05/2017",
    transactionAmount: "20,000",
    purpose: "Membership Dues",
    status: "Pending"
  },
  {
    paymentREFNO: "10230600A",
    paymentDate: "02/05/2017",
    transactionAmount: "20,000",
    purpose: "Examination Application",
    status: "Successful"
  },
  {
    paymentREFNO: "10230600A",
    paymentDate: "02/05/2017",
    transactionAmount: "20,000",
    purpose: "Membership Dues",
    status: "Pending"
  },
  {
    paymentREFNO: "10230600A",
    paymentDate: "02/05/2017",
    transactionAmount: "20,000",
    purpose: "Examination Application",
    status: "Successful"
  },
  {
    paymentREFNO: "10230600A",
    paymentDate: "02/05/2017",
    transactionAmount: "20,000",
    purpose: "Membership Dues",
    status: "Pending"
  },
]

export const examApplicationData = [
  {
    examSubject: 'Financial Management & Strategy- PRO2/001',
    examDate: '2024/06/04 : 9:00AM-12:00 noon',
    examFee: '10,000',
  },
  {
    examSubject: 'Financial Management & Strategy- PRO2/001',
    examDate: '2024/06/04 : 9:00AM-12:00 noon',
    examFee: '10,000',
  },
  {
    examSubject: 'Financial Management & Strategy- PRO2/001',
    examDate: '2024/06/04 : 9:00AM-12:00 noon',
    examFee: '10,000',
  },
  {
    examSubject: 'Financial Management & Strategy- PRO2/001',
    examDate: '2024/06/04 : 9:00AM-12:00 noon',
    examFee: '10,000',
  },
  {
    examSubject: 'Financial Management & Strategy- PRO2/001',
    examDate: '2024/06/04 : 9:00AM-12:00 noon',
    examFee: '10,000',
  },
  {
    examSubject: 'Financial Management & Strategy- PRO2/001',
    examDate: '2024/06/04 : 9:00AM-12:00 noon',
    examFee: '10,000',
  },

]

export const examresultData = [
  {
    subject: "Financial Management & Strategy- PRO2/001",
    grade: "Pass",
  },
  {
    subject: "Financial Management & Strategy- PRO2/001",
    grade: "DISTINCTION",
  },
  {
    subject: "Financial Management & Strategy- PRO2/001",
    grade: "MERIT",
  },
]

