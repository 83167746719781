import React from 'react';
import { Flex, Box } from '@chakra-ui/react';
import EducationPieChart from '../components/EducationPieChart';
import EducationBarCharts from '../components/EducationBarCharts';
import EducationPieCharts2 from '../components/EducationPieCharts2';

const EducationOMDashboard = () => {
    const data = [
        {
          name: 'Total Applicants For Student', number: 200, colors: ['#F17D8B','#EBE3EB' ],
          pieChartData :[ { name: "Approved", value: 150}, { name: "Declined ", value: 50}],
        },
        {
            name: 'Total Applicants For NYSC', number: 50, colors:['#7F3183', '#EBE3EB'] ,
            pieChartData :[ { name: "Approved", value: 30}, { name: "Declined ", value: 20}],
        },
        {
            name: 'Total Applicants For Graduate', number: 20, colors:['#A0B6FD', '#EBE3EB' ] ,
            pieChartData :[ { name: "Approved", value: 15}, { name: "Declined ", value: 5}],
        },
        {
            name: 'Total Applicants For Associate', number: 20, colors:['#18203A', '#EBE3EB' ] ,
            pieChartData :[ { name: "Approved", value: 15}, { name: "Declined ", value: 5}],
        },
        {
            name: 'Total Applicants For Fellow', number: 35, colors:['#E9BF72','#EBE3EB'] ,
            pieChartData :[ { name: "Approved", value: 25}, { name: "Declined ", value: 10}],
        },
    ];
    
  return (
    <Flex flexDirection={'column'} gap={'20px'} py={'30px'} minH={'100vh'} px={3} >
        <Box w={['100%', '100%', '100%']}>
         <EducationPieChart details={data} />
        </Box>
        <Flex mt={6} flexDirection={['column', 'column','column', 'row']} justifyContent={['center', 'center', 'space-between']} alignItems={'center'} gap={'20px'}>
            <Box w={['100%', '100%', '100%','70%', '75%']}>
                <EducationBarCharts />
            </Box>
            <Box w={['100%', '100%', '100%','30%', '25%']}>
                <EducationPieCharts2 />
            </Box>
        </Flex>
        
    </Flex>
  )
}

export default EducationOMDashboard;