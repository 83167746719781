import React from 'react';
import { Box, Divider, Flex, Text } from '@chakra-ui/react';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import { membershipPieChartData } from '../data/mock';

const MembershipPieChart = () => {
    const colors = ["#A0B6FD", "#953B8B", "#F07E8D", "#FFC34F"];
    const sumTotal = membershipPieChartData.reduce((acc, data) => acc + data.value, 0);
    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    const { value } = membershipPieChartData[index];

    return (
        <text style={{fontWeight:"800", fontSize:"14px", lineHeight:"9.68px", fontFamily:"inter"}} x={x} y={y} fill="rgba(255, 255, 255, 0.5)" textAnchor="middle" dominantBaseline="middle">
        {value}
        </text>
    );
    };

  return (
    <Box bg={'#FFFFFF'} borderRadius={'20px'} p={5} h={['100%','100%','100%', '375px', '375px']}
    sx={{
        overflowY: "scroll",
      scrollbarWidth: "none", 
      msOverflowStyle: "none", 
      "-ms-overflow-style": "none", 
      "&::-webkit-scrollbar": {
        display: "none", 
      },
    }}
    overflowY={"auto"}
    >
        <Flex justifyContent={'space-between'} alignItems={'center'} >
            <Flex flexDirection={'column'}>
                <Text color={'#4D4D4D'} letterSpacing={'-0.154px'} fontSize={'16px'} fontWeight={'500'}>
                    Member Upgrade Per Category
                </Text>
                <Text color={'#4D4D4D'} fontSize={'10px'} fontWeight={'400'} lineHeight={'13.62px'} letterSpacing={'-0.15px'}>{sumTotal} Total Upgrades</Text>
            </Flex>
        </Flex>
        <Flex w="100%" flexDirection={'column'} >
            <ResponsiveContainer width="100%" height={300} >
                <PieChart>
                    <Pie
                    data={membershipPieChartData}
                    cx="50%"
                    cy="50%"
                    innerRadius={45}
                    outerRadius={85}
                    fill="#8884d8"
                    paddingAngle={5}
                    dataKey="value"
                    cornerRadius={8}
                    labelLine={false}
                    label={renderCustomizedLabel}
                    >
                    {membershipPieChartData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                    ))}
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
            <Divider orientation='horizontal'  border={`1px solid #C6C6C666`} />
            <Flex direction="column" justify="center" py={5} gap={2}>
            {
            membershipPieChartData.map((item, index) => (
            <Flex alignItems={'center'} justifyContent={'space-between'} key={index} gap={3} >
                <Flex alignItems={'center'} gap={2}>
                    <Box bg={colors[index % colors.length]} h={2} w={4} borderRadius="3px" ></Box>
                    <Text fontSize="12px" color="#000000B2" fontWeight={'700'} fontFamily={'inter'}>
                        {item.name}
                    </Text>
                </Flex>
                <Text color={'#717171'} fontSize={'12px'} fontWeight={'500'} lineHeight={'12.61px'}>{item.value} Registered students</Text>
            </Flex>
            ))
            }
        </Flex>
        </Flex>
    </Box>
  )
}

export default MembershipPieChart;