import React from 'react';
import { Box, Flex, Image, Text } from '@chakra-ui/react';
// import { format } from 'date-fns';
import ApproveDecline from './ApproveDecline';
import {File_URL} from "../config/settings";

const PersonalInformation = ({individualDetails}) => {
  const tagData = [
    {title:"Fullname:", discription:individualDetails?.personalInformation?.firstName + " " + individualDetails?.personalInformation?.lastName + " " + individualDetails?.personalInformation?.otherName},
    {title:"Date of Birth:", discription:individualDetails?.personalInformation?.dateOfBirth},
    {title:"Gender:", discription:individualDetails?.personalInformation?.gender},
    {title:"Telephone:", discription:individualDetails?.personalInformation?.phoneNumber},
    {title:"Address:", discription:individualDetails?.personalInformation?.homeAddress},
    {title:"Country:", discription:individualDetails?.personalInformation?.country},
    {title:"Postcode:", discription:individualDetails?.personalInformation?.postCode},
    {title:"State of Origin:", discription:individualDetails?.personalInformation?.stateOfOrigin},
  ]

  return (
    <Box>
      <Flex justifyContent={'flex-start'} alignItems={'center'} my={6} >
        <Image src={`${File_URL}${individualDetails?.personalInformation?.passport}`} alt='Profile Pics' rounded={'full'} width={'183px'} height={'183px'} overflow={'hidden'} objectFit={'cover'} border={'5px solid #D11A2A'} objectPosition={'center'} />
      </Flex>
      <Box h={'250px'}
        overflow={'scroll'}
      >

      {
      tagData?.map((data, index) =>(
      <Flex px={[1, null, null]} my={5} fontSize={'18px'} fontWeight={'500'} fontFamily={'roboto'} justifyContent={'flex-start'} alignItems={'center'} mb={4} key={index} gap={[6, 3, 3]}
      
      >
        <Text width={'35%'} color={'#121212'} fontWeight={'400'} lineHeight={'18.9px'}  >{data.title}</Text>
        <Text  width={'65%'} color={'#7A7A7A'}  lineHeight={'21.09px'} >{data.discription}</Text>
      </Flex>
      ))
      }
      <ApproveDecline individualDetails={individualDetails} />
      </Box>
  </Box>
  )
}

export default PersonalInformation;