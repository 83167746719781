import React, {useCallback, useEffect, useState} from 'react';
import { Box, Flex,Text, Button, Menu, MenuButton, MenuItem, MenuList, Alert,AlertIcon, AlertTitle } from '@chakra-ui/react';
import Create from '../components/layout/Create';
import { useDispatch, useSelector} from 'react-redux';
import {setFormModal} from "../redux/slices/modalSlice";
import CustomTable from '../components/CustomTable';
import { PiExport } from "react-icons/pi";
import {BsThreeDotsVertical} from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import {fetchExamCategory, setExamCategoryDetails, setEditExamCategory} from "../redux/slices/examCategorySubjectSlice";
import { checkPermissions} from '../components/CheckPermission';
import SortBy from '../components/SortBy';
import {clearsearchFilter} from "../redux/slices/searchFilterSlice";
import DotLoader from '../components/loaders/DotLoader';
import { BASE_URL } from "../config/settings";
import {setloadTost} from "../redux/slices/toastSlice";
import httpRequest from "../utils/httpRequest";

const ExamCategories = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const errorsArray = Array.isArray(error) ? error : [error];

  const getAllExamCategory = useCallback( async() => {
    setLoading(true);
    setError("");
    try {
      const response = await dispatch(fetchExamCategory(1000));
      if (response?.payload?.name === "AxiosError"){
        setLoading(false);
        setError(response?.payload?.response?.data?.errors?.map((errors) => errors) || response?.payload?.response?.data?.error?.description || "Failed to get Exam Category, please check your request/network");
      }
      setLoading(false);
      
    } catch (error) {
      setError(error?.payload?.message || "Failed to get Exam Category, please check your network");
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(clearsearchFilter());
    getAllExamCategory();
  }, [dispatch, getAllExamCategory]);

  const examCategoryData = useSelector((state) => state?.examCategorySubject?.examCategory?.data);
  
  const addCategoryPermission = checkPermissions( 'Exam Setup', 'Exam Categories', 'Create', 'Granted' );
  const editCategoryPermission = checkPermissions( 'Exam Setup', 'Exam Categories', 'Edit', 'Granted' );
  const deleteCategoryPermission = checkPermissions( 'Exam Setup', 'Exam Categories', 'Delete', 'Granted' );

  const createData = {
    onClick: () => { dispatch(setFormModal({status:true, title: "Add Category ", type:"addCategory"})); },
    createText: "Add Category ",
  }
  const handleUpdate = (item) => {
    dispatch(setExamCategoryDetails(item));
    navigate(`/examsetup/examcategories/exam-subjects?categoryId=${item?.id}`);
  }

  const handleEdit = (item) => {
    dispatch(setEditExamCategory(item));
    dispatch(setFormModal({status:true, title: "Edit Category", type:"editCategory"}));
  }

  const handleDelete = async (item) => {
    setError('');
   try {
      const response = await httpRequest(`${BASE_URL}/v2/ExamCategory/${item?.id}`,
      'delete', 
    );
    if(response?.isSuccess === true){
      setError('');
      dispatch(fetchExamCategory(1000));
      dispatch(setloadTost({ title: 'Exam Category Deleted', description: `${item?.name}  Deleted successfully`, status: 'success', duration: 5000, isClosable: true, position: 'top' }));
   }else{
      setError(response?.payload?.response?.data?.errors?.map((errors) => errors) || response?.payload?.response?.data?.error?.description || "Failed to delete Exam Category");
    }
    
   } catch (error) {
    setError(error?.response?.message || "Failed to delete Exam Category");
   }

}

  const tableProps = {
    data:examCategoryData,
    title: {
      categoryName: "Category Name",
      categoryCode: "Category Code",
      status:"Status",
      examSubject:" ",
      Action:'',
    },
    dataKeys: [
      "categoryName",
      "categoryCode",
      "status",
      "examSubject",
      "Action",
    ],
    transformRow: (item) => ({
      categoryName:item && <Box style={{cursor:'pointer'}}>{item?.name}</Box>,
      categoryCode: item && <Box style={{cursor:'pointer'}}>{item?.code}</Box>,
      status: item && <Box style={{cursor:'pointer'}} color={item?.isActive ? "#3064FC" : "#D11A2A"}>{item?.isActive ? "Active" : "Inactive"}</Box>,
      examSubject: item && <Box style={{cursor:'pointer'}} color={'#3064FC'} textDecoration={'underline'} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'} onClick={() => handleUpdate(item)}> View Exam Subject</Box>,
      Action:item && (
        <Menu>
          {
            !editCategoryPermission && !deleteCategoryPermission ? null : (
              <MenuButton>
                <BsThreeDotsVertical />
              </MenuButton>
            ) 
          }     
          <MenuList >
            {
            editCategoryPermission? (
                <MenuItem onClick={() => handleEdit(item)} >Edit </MenuItem>
            ) : null
            }
            {
            deleteCategoryPermission? (
                <MenuItem onClick={() => handleDelete(item)} > Delete </MenuItem>
            ) : null
            }
          </MenuList>
        </Menu>
      )
    }),
  };

  return (
    <Box bg={'#FFFFFF'} py={'30px'} minH={'100vh'} px={3}>
      <Flex flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} gap={'20px'} borderBottom={'1px solid #B4B1B1'} pb={2} mb={5}>
        <Text fontSize={'18px'} fontWeight={'700'} color={'#D11A2A'}> Exam Categories</Text>
        <Flex flexDirection={["column", "column", "row"]} alignItems={'center'} gap={[3, null, null]}>
          {
            addCategoryPermission? (
              <Create {...createData} />
            ) : null
          }
          <SortBy />
          <Button leftIcon={<PiExport style={{width: "15px", height: "15px"}} />} bg={'transparent'} _hover={{backgroundColor:"transparent"}} _active={{backgroundColor:"transparent"}} color={'#404040'}
            fontFamily={'inter'} fontSize={'12px'} fontWeight={'500'} lineHeight={'24px'} letterSpacing={'0.4px'} 
          >
            Export
          </Button>
        </Flex>
      </Flex>
    {
      loading ? (
        <Flex justifyContent={'center'} alignItems={'center'}>
          <DotLoader />
        </Flex>
      ): (
        <>
          {
          error ? (
            <Alert status="error" className="mt-3" mb={3}>
                <AlertIcon />
                <AlertTitle>{errorsArray.join(', ')}</AlertTitle>
            </Alert>
            ) 
            : (<CustomTable {...tableProps} />)
          }
        </>
      )
    }
    </Box>
  )
}

export default ExamCategories;