import React, { useState } from 'react'
import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, Input, Select, Text } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import ExamApplicantListingTable from '../components/ExamApplicantListingTable';

function ExamApplicantReport() {
    const [toggleReport, setToggleReport] = useState(false);

    const initialValues = {
        examCentre: '',
        examDiet: '',
        examType: '',
        examYear: '',
    };

    const handleSubmit = async (values) => {
        setToggleReport(!toggleReport)
    }
  return (
    <>
    <Box bgColor={'white'} height={["850px", "", '370px']} px={'30px'} py={'20px'} mb={5}>
        <Text color={'#3A3541DE'} fontSize={'16px'} fontWeight={500}>Search Filters</Text>
        <Formik
            enableReinitialize
            initialValues={initialValues}
            // validationSchema={approvalReportSchema}
            onSubmit={(values) => handleSubmit(values)}
        >
            {({
                errors, touched, values, handleBlur, handleChange
            }) => (

                <Form>
                    <Flex flexDirection={["column", "column", "row"]} alignItems={"center"} gap={3}>
                        <FormControl mt={4} isInvalid={errors.examYear && touched.examYear} color="#121212">
                            <FormLabel htmlFor='examYear' fontSize={'16px'}>Exam Year</FormLabel>
                            <Select
                                height={'52px'}
                                name="examYear"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                bg={'#F3F3F3'}
                                borderRadius={"0"}
                                focusBorderColor="#ffffff"
                                borderColor={"#FFFFFF"}
                                _hover={{ borderColor: "#FFFFFF" }}
                                _placeholder={{ color: "#B4B1B1" }}
                                value={values?.examYear}
                            >
                                <option value='2020'>2020</option>
                                <option value='2021'>2021</option>
                            </Select>
                            {touched.examYear && errors.examYear && (
                                <FormErrorMessage>{errors.examYear}</FormErrorMessage>
                            )}
                        </FormControl>
                        <FormControl mt={4} isInvalid={errors.examCentre && touched.examCentre} color="#121212">
                            <FormLabel htmlFor='examCentre' fontSize={'16px'}>Exam Centre</FormLabel>
                            <Select
                                height={'52px'}
                                name="examCentre"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                bg={'#F3F3F3'}
                                borderRadius={"0"}
                                focusBorderColor="#ffffff"
                                borderColor={"#FFFFFF"}
                                _hover={{ borderColor: "#FFFFFF" }}
                                _placeholder={{ color: "#B4B1B1" }}
                                value={values?.examCentre}
                            >
                                <option value='active'>active</option>
                                <option value='inactive'>inactive</option>
                            </Select>
                            {touched.examCentre && errors.examCentre && (
                                <FormErrorMessage>{errors.examCentre}</FormErrorMessage>
                            )}
                        </FormControl>
                        <FormControl mt={4} isInvalid={errors.diet && touched.diet} color="#121212">
                            <FormLabel htmlFor='diet' fontSize={'16px'}>Diet</FormLabel>
                            <Select
                                height={'52px'}
                                name="diet"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                bg={'#F3F3F3'}
                                borderRadius={"0"}
                                focusBorderColor="#ffffff"
                                borderColor={"#FFFFFF"}
                                _hover={{ borderColor: "#FFFFFF" }}
                                _placeholder={{ color: "#B4B1B1" }}
                                value={values?.diet}
                            >
                                <option value='active'>active</option>
                                <option value='inactive'>inactive</option>
                            </Select>
                            {touched.diet && errors.diet && (
                                <FormErrorMessage>{errors.diet}</FormErrorMessage>
                            )}
                        </FormControl>
                    </Flex>
                    <Flex flexDirection={["column", "column", "row"]} alignItems={"center"} gap={3}>
                        <FormControl mt={4} isInvalid={errors.examType && touched.examType} color="#121212">
                            <FormLabel htmlFor='examType' fontSize={'16px'}>Exam Type</FormLabel>
                            <Select
                                height={'52px'}
                                name="examType"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                bg={'#F3F3F3'}
                                borderRadius={"0"}
                                focusBorderColor="#ffffff"
                                borderColor={"#FFFFFF"}
                                _hover={{ borderColor: "#FFFFFF" }}
                                _placeholder={{ color: "#B4B1B1" }}
                                value={values?.examType}
                            >
                                <option value='1'>1</option>
                                <option value='2'>2</option>
                            </Select>
                            {touched.examType && errors.examType && (
                                <FormErrorMessage>{errors.examType}</FormErrorMessage>
                            )}
                        </FormControl>
                        <FormControl mt={4} isInvalid={errors.startDate && touched.startDate}
                            color="#121212" fontFamily={'inter'}>
                            <FormLabel htmlFor='startDate' fontSize={"16px"}>Start Date</FormLabel>
                            <Input
                                height={'52px'}
                                type="date"
                                placeholder="Pick a Date"
                                borderRadius={"0"}
                                fontSize={"small"}
                                bg={'#F3F3F3'}
                                name="startDate"
                                focusBorderColor="#ffffff"
                                borderColor={"#FFFFFF"}
                                _hover={{ borderColor: "#FFFFFF" }}
                                _placeholder={{ color: "#B4B1B1" }}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values?.startDate}
                            />
                            {touched.startDate && errors.startDate && (
                                <FormErrorMessage>{errors.startDate}</FormErrorMessage>
                            )}
                        </FormControl>
                        <FormControl mt={4} isInvalid={errors.endDate && touched.endDate}
                            color="#121212" fontFamily={'inter'}>
                            <FormLabel htmlFor='endDate' fontSize={"16px"}>End Date</FormLabel>
                            <Input
                                height={'52px'}
                                type="date"
                                placeholder="Pick a Date"
                                borderRadius={"0"}
                                fontSize={"small"}
                                bg={'#F3F3F3'}
                                name="endDate"
                                cursor={"pointer"}
                                focusBorderColor="#ffffff"
                                borderColor={"#FFFFFF"}
                                _hover={{ borderColor: "#FFFFFF" }}
                                _placeholder={{ color: "#B4B1B1" }}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values?.endDate}
                            />
                            {touched.endDate && errors.endDate && (
                                <FormErrorMessage>{errors.endDate}</FormErrorMessage>
                            )}
                        </FormControl>
                    </Flex>
                    <Flex justifyContent={'flex-end'} alignItems={'center'}>
                        <Button
                            mt={10} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'}
                            width={"118px"}
                            background={"#D11A2A"}
                            _hover={{ background: " " }}
                            color={"#FFFFFF"}
                            type="submit"
                        // isLoading={loading}
                        // loadingText="Saving ..."
                        >
                            Fetch Report
                        </Button>
                    </Flex>
                </Form>
            )}
        </Formik>
    </Box>
    {
        toggleReport ? (<ExamApplicantListingTable />) : null
    }
</>
  )
}

export default ExamApplicantReport