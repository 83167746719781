import React, {useState} from 'react';
import { Form, Formik } from "formik";
import { Button, FormControl, FormErrorMessage, FormLabel, Input, Select, Flex, Alert,AlertIcon, AlertTitle  } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { setFormModal } from "../redux/slices/modalSlice";
import { addUserFormSchema } from "../utils/formValidationSchema";
import {fetchUsers, createUser} from "../redux/slices/userMgtUsersSlice";
import {setloadTost} from "../redux/slices/toastSlice";

const AddUserForm = () => {
  const dispatch = useDispatch();
  const RoleData = useSelector((state) => state?.userMgtRole?.roles?.data);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const errorsArray = Array.isArray(error) ? error : [error];
  const departmentData = useSelector((state) => state?.departmentMgt?.department?.data);

  const initialValues = {
    firstName: '',
    lastName: '',
    assignRole: '',
    email: '',
    phoneNumber: '',
    department: '',
    staffId: '',
  };

  const handleSubmit = async (values) => {
    // console.log(values);
    setLoading(true);
    setError("");
    try {
      const formData = {
        email: values?.email,
        firstName: values?.firstName,
        lastName: values?.lastName,
        phoneNumber: values?.phoneNumber,
        role: values?.assignRole,
        staffId: values?.staffId,
        departmentId: values?.department
      };

      const response = await dispatch(createUser(formData));
      // console.log(response);
      if (response?.payload?.isSuccess === true){
        setLoading(false);
        dispatch(fetchUsers());
        dispatch(setFormModal({ status: false, data: null }));
        dispatch(setloadTost({ title: 'User Created', description: `${values?.firstName} ${values?.lastName} created successfully`, status: 'success', duration: 5000, isClosable: true, position: 'top' }));
      }else{
        setLoading(false);
        setError(response?.payload?.errors?.map((errors) => errors) || response?.payload?.error?.description || "Failed to create user");
      }
      
    } catch (error) {
      setError(error?.payload?.message || "Failed to create user, please check your network");
    }finally{
      setLoading(false);
    }
  }

  return (
    <>
      {
        error &&(
        <Alert status="error" className="mt-3" mb={3}>
            <AlertIcon />
            <AlertTitle>{errorsArray.join(', ')}</AlertTitle>
        </Alert>
        )
      }
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={addUserFormSchema}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({ errors, touched, values, handleBlur, handleChange }) => (
          <Form>
            <Flex flexDirection={["column", "row", "row"]} alignItems={"center"} gap={3}>
              <FormControl mt={4} isInvalid={errors.firstName && touched.firstName}
                  color="#121212" fontFamily={'inter'}>
                <FormLabel htmlFor='firstName' fontSize={"16px"}>First Name</FormLabel>
                <Input
                  placeholder="Enter Here"
                  fontSize={"small"}
                  bg={'#F3F3F3'}
                  name="firstName"
                  focusBorderColor="#ffffff"
                  borderRadius={'0'}
                  borderColor={"#FFFFFF"}
                  _placeholder={{ color: "#B4B1B1" }}
                  _hover={{ borderColor: "#FFFFFF" }}
                  type="text"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values?.firstName}
                />
                {touched.firstName && errors.firstName && (
                  <FormErrorMessage>{errors.firstName}</FormErrorMessage>
                )}
              </FormControl>
              <FormControl mt={4} isInvalid={errors.lastName && touched.lastName}
                  color="#121212" fontFamily={'inter'}>
                <FormLabel htmlFor='lastName' fontSize={"16px"}>Last Name</FormLabel>
                <Input
                  placeholder="Enter Here "
                  fontSize={"small"}
                  bg={'#F3F3F3'}
                  name="lastName"
                  focusBorderColor="#ffffff"
                  borderRadius={'0'}
                  borderColor={"#FFFFFF"}
                  _placeholder={{ color: "#B4B1B1" }}
                  _hover={{ borderColor: "#FFFFFF" }}
                  type="text"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values?.lastName}
                />
                {touched.lastName && errors.lastName && (
                  <FormErrorMessage>{errors.lastName}</FormErrorMessage>
                )}
              </FormControl>
            </Flex> 
            <Flex flexDirection={["column", "row", "row"]} alignItems={"center"} gap={3}>
              <FormControl mt={4} isInvalid={errors.assignRole && touched.assignRole}
                color="#121212" fontFamily={'inter'}>
                <FormLabel htmlFor='assignRole' fontSize={"16px"}>Assign Role</FormLabel>
                <Select
                  name="assignRole"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  bg={'#F3F3F3'}
                  borderRadius={"0"}
                  focusBorderColor="#ffffff"
                  borderColor={"#FFFFFF"}
                  // placeholder='Select Role'
                  _hover={{ borderColor: "#FFFFFF" }}
                  _placeholder={{ color: "#B4B1B1" }}
                  value={values?.assignRole}
                >
                  {
                    RoleData?.map((role) => (
                      <option key={role?.id} value={role?.name}>{role?.name}</option>
                    ))
                  }
                </Select>
                {touched.assignRole && errors.assignRole && (
                  <FormErrorMessage>{errors.assignRole}</FormErrorMessage>
                )}
              </FormControl>
              <FormControl mt={4} isInvalid={errors.email && touched.email}
                color="#121212" fontFamily={'inter'}>
                <FormLabel htmlFor='email' fontSize={"16px"}> Email Address</FormLabel>
                <Input
                  type="email"
                  placeholder="Enter Here"
                  borderRadius={"0"}
                  fontSize={"small"}
                  bg={'#F3F3F3'}
                  name="email"
                  focusBorderColor="#ffffff"
                  borderColor={"#FFFFFF"}
                  _hover={{ borderColor: "#FFFFFF" }}
                  _placeholder={{ color: "#B4B1B1" }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values?.email}
                />
                {touched.email && errors.email && (
                  <FormErrorMessage>{errors.email}</FormErrorMessage>
                )}
              </FormControl>
            </Flex>
            <Flex flexDirection={["column", "row", "row"]} alignItems={"center"} gap={3}>
              <FormControl mt={4} isInvalid={errors.phoneNumber && touched.phoneNumber}
                  color="#121212" fontFamily={'inter'}>
                <FormLabel htmlFor='phoneNumber' fontSize={"16px"}>Phone Number</FormLabel>
                <Input
                  placeholder="Enter Here"
                  fontSize={"small"}
                  bg={'#F3F3F3'}
                  name="phoneNumber"
                  focusBorderColor="#ffffff"
                  borderRadius={'0'}
                  borderColor={"#FFFFFF"}
                  _placeholder={{ color: "#B4B1B1" }}
                  _hover={{ borderColor: "#FFFFFF" }}
                  type="tel"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values?.phoneNumber}
                />
                {touched.phoneNumber && errors.phoneNumber && (
                  <FormErrorMessage>{errors.phoneNumber}</FormErrorMessage>
                )}
              </FormControl>
              <FormControl mt={4} isInvalid={errors.department && touched.department}
                color="#121212" fontFamily={'inter'}>
                <FormLabel htmlFor='department' fontSize={"16px"}>Department</FormLabel>
                <Select
                  name="department"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  bg={'#F3F3F3'}
                  borderRadius={"0"}
                  focusBorderColor="#ffffff"
                  borderColor={"#FFFFFF"}
                  // placeholder='Select Department'
                  _hover={{ borderColor: "#FFFFFF" }}
                  _placeholder={{ color: "#B4B1B1" }}
                  value={values?.department}
                >
                  {
                    departmentData && departmentData?.map((department) => (
                      <option key={department?.id} value={department?.id}>{department?.name}</option>
                    ))
                  }

                </Select>
                {touched.department && errors.department && (
                  <FormErrorMessage>{errors.department}</FormErrorMessage>
                )}
              </FormControl>
              <FormControl mt={4} isInvalid={errors.staffId && touched.staffId}
                  color="#121212" fontFamily={'inter'}>
                <FormLabel htmlFor='staffId' fontSize={"16px"}>Staff ID</FormLabel>
                <Input
                  placeholder="Enter Here "
                  fontSize={"small"}
                  bg={'#F3F3F3'}
                  name="staffId"
                  focusBorderColor="#ffffff"
                  borderRadius={'0'}
                  borderColor={"#FFFFFF"}
                  _placeholder={{ color: "#B4B1B1" }}
                  _hover={{ borderColor: "#FFFFFF" }}
                  type="text"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values?.staffId}
                />
                {touched.staffId && errors.staffId && (
                  <FormErrorMessage>{errors.staffId}</FormErrorMessage>
                )}
              </FormControl>
            </Flex>

            <Flex justifyContent={'center'} alignItems={"center"} gap={5}>
              <Button
                mt={10} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'}
                width={"118px"}
                background={"#D11A2A"}
                _hover={{ background: " " }}
                color={"#FFFFFF"}
                type="submit"
                isLoading={loading}
                loadingText="Saving ..."
              >
                Add User
              </Button>
              <Button
                mt={10} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'}
                width={"118px"}
                background={""}
                _hover={{ background: " " }}
                color={"#8F9BB3"}
                onClick={() => {
                  dispatch(setFormModal({ status: false, data: null }));
                }}
                loadingText="Saving ..."
              >
                Cancel
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default AddUserForm