import React, { useState } from "react";
import { Box, Flex, Alert, AlertIcon, AlertTitle } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
// import { setFormModal } from "../redux/slices/modalSlice";
import CustomTable from "../components/CustomTable";
import DotLoader from '../components/loaders/DotLoader';
import { setloadTost } from "../redux/slices/toastSlice";
import { fetchExamCenterChangeDetails } from "../redux/slices/examMgtSlice";
import { setFormModal } from "../redux/slices/modalSlice";

const ExamCentrePendingTable = ({ examCentreChangeApplication, loading, error, errorsArray }) => {
  const dispatch = useDispatch();
  const [loadingDetails, setLoadingDetails] = useState({});

  const handleUpdate = async (item) => {
    setLoadingDetails((prev) => ({ ...prev, [item.id]: true }));
    try {
      const response = await dispatch(fetchExamCenterChangeDetails(item?.id));
      if (response?.payload?.isSuccess === true) {
        dispatch(setFormModal({ status: true, title: "Exam Application Details", type: "examIndividualDetails", size: "3xl", data: response?.payload }));
      } else if (response?.payload?.name === "AxiosError") {
        dispatch(setloadTost({ title: 'Failed to view details', description: `${response?.payload?.errors?.map((errors) => errors) || response?.payload?.error?.description || `Failed to View ${item?.fullName} Details, please try again`}`, status: 'error', duration: 5000, isClosable: true, position: 'top' }));
      }
    } catch (error) {
      dispatch(setloadTost({ title: 'Failed to view details', description: `${error?.payload?.message || `Failed to View ${item?.fullName} Details, please try again`}`, status: 'error', duration: 5000, isClosable: true, position: 'top' }));
    } finally {
      setLoadingDetails((prev) => ({ ...prev, [item.id]: false }));
    }
  };

  const tableProps = {
    data: examCentreChangeApplication?.data,
    title: {
      fullName: "Fullname",
      appNo: "Application Number",
      email: "Email Address",
      oldCentre: "Old Centre",
      newCentre: "New Centre",
      view: "",
    },
    dataKeys: ["fullName", "appNo", "email", "oldCentre", "newCentre", "view"],

    transformRow: (item) => ({
      fullName: <Box style={{ cursor: "pointer" }}>{item?.fullName}</Box>,
      appNo: <Box style={{ cursor: "pointer" }}>{item?.applicationNumber}</Box>,
      email: <Box style={{ cursor: "pointer" }}>{item?.email}</Box>,
      oldCentre: <Box style={{ cursor: "pointer" }}>{item?.oldCentre}</Box>,
      newCentre: <Box style={{ cursor: "pointer" }}>{item?.newCentre}</Box>,
     
      view: item && (
        <Box
          style={{ cursor: "pointer" }}
          color={"#FFFFFF"}
          bg={"#812773"}
          borderRadius={"5px"}
          p={"10px 15px"}
          textAlign={"center"}
          fontSize={"14px"}
          fontWeight={"400"}
          lineHeight={"16.94px"}
          fontFamily={"inter"}
          onClick={() => handleUpdate(item)}
        >
          {loadingDetails[item.id] ? 'Loading...' : "View Details"}
        </Box>
      ),
    }),
  };

  return (
    <>
      {loading ? (
        <Flex justifyContent={'center'} alignItems={'center'}>
          <DotLoader />
        </Flex>
      ) : (
        <>
          {error ? (
            <Alert status="error" className="mt-3" mb={3}>
              <AlertIcon />
              <AlertTitle>{errorsArray.join(', ')}</AlertTitle>
            </Alert>
          ) : (
            <CustomTable {...tableProps} />
          )}
        </>
      )}
    </>
  );
};

export default ExamCentrePendingTable;
