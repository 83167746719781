import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import ApproveDecline from './ApproveDecline';
import { useSelector } from 'react-redux';

const QualificationsInformation = ({individualDetails}) => {
  const entryQualificationType = useSelector((state) => state?.entryQualificationType);
  
  const tagData2 = [
    {title:"Entry Qualification Type", discription:individualDetails?.qualificationInformation?.entryQualificationTypeId},
    {title:"Applicable Exception", discription:individualDetails?.qualificationInformation?.applicableException},
    {title:"Institution Attended", discription:individualDetails?.qualificationInformation?.certificates[0]?.schoolAttended},
    {title:"Level Attained", discription:individualDetails?.qualificationInformation?.certificates[0]?.levelAttained},
    {title:"Course", discription:individualDetails?.qualificationInformation?.certificates[0]?.course},
  ]

  console.log('discription:individualDetails?.qualificationInformation?.entryQualificationType' , entryQualificationType)
  return (
    <>
        <Box h={'60vh'}
        overflow={'scroll'}
      >
      {
      tagData2?.map((data, index) =>(
      <Flex px={[1, null, null]} my={5} fontSize={'18px'} fontWeight={'500'} fontFamily={'roboto'} justifyContent={'flex-start'} alignItems={'center'} mb={4} key={index} gap={[6, 3, 3]}
      
      >
        <Text width={'35%'} color={'#121212'} fontWeight={'400'} lineHeight={'18.9px'}  >{data.title}</Text>
        <Text  width={'65%'} color={'#7A7A7A'}  lineHeight={'21.09px'} >{data.discription}</Text>
      </Flex>
      ))
      }
      <ApproveDecline individualDetails={individualDetails} />
      </Box>
    </>
  )
}

export default QualificationsInformation;