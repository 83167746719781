import React from 'react';
import {Flex, Grid, GridItem, Box, Text} from '@chakra-ui/react';
import { barChartData } from '../data/mock';
import DashboardBarChart from '../components/DashboardBarChart';
import FinancePieChart from '../components/FinancePieChart';
import DasboardCards from '../components/DasboardCards';

const FinanceDashboard = () => {
    const data = [
        {
            name: 'Total Amount From Member Application', number: 200000, colors: ['#F17D8B', '#EBE3EB', '#D5D5D5',],
            pieChartData: [{ name: "Student", value: 100000 }, { name: "NYSC", value: 80000 }, { name: "Graduate", value: 50000 }, { name: "Associate", value: 50000 },  { name: "Fellow", value: 90000 }],
        },
        {
            name: 'Total Amount From Exam Application', number: 50000, colors: ['#A0B6FD', '#EBE3EB', '#D5D5D5'],
            pieChartData: [{ name: "Pre-professional", value: 30000 }, { name: "Foundation ", value: 10000 }, { name: "Professional1 ", value: 25000 }],
        },
        {
            name: 'Total Amount From Event Application', number: 20, colors: ['#EFF0F6', '#953B8B'],
            pieChartData: [{ name: "Members", value: 5000 }, { name: "Non-members ", value: 5000 }],
        },

    ];
    return (
        <>
            <Text>Finance Dashboard</Text>
            <Flex gap={5} minH={'100vh'} flexDirection={['column', 'column', 'column', 'row']}>
                <Flex flexDirection={'column'} gap={'20px'} py={'30px'} px={3} w={['100%', '100%', '100%', '70%', '75%']} >
                    <Grid templateColumns={['repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(3, 1fr)']} gap={[5, 5, 5, 3]}>
                        {
                            data?.map((item, index) => (
                                <GridItem key={index} >
                                    <DasboardCards name={item?.name} number={item?.number} colors={item?.colors} pieChartData={item?.pieChartData} />
                                </GridItem>
                            ))
                        }
                    </Grid>
                    <DashboardBarChart barChartData={barChartData} />
                </Flex>
                <Box mt={7} width={['100%', '100%', '100%', '30%', '25%']} >
                    <FinancePieChart />
                </Box>
            </Flex>
        </>
    )
}

export default FinanceDashboard