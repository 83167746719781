import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/settings";
import httpRequest from "../../utils/httpRequest";

const initialState = {
  user: null,
  loading: false,
  error: null,
  userProfile: null,
  memberProfile: null,
};

export const fetchUserProfile = createAsyncThunk(
  "user/fetchUserProfile",
  async () => {
    return httpRequest(`${BASE_URL}/v2/Administrative/profile`);
  }
);

export const fetchMemberUserProfile = createAsyncThunk(
  "user/fetchMemberUserProfile",
  async () => {
    return httpRequest(`${BASE_URL}/v2/MembershipManagement/profile`);
  }
);

const authSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    addLoginUser: (state, { payload }) => {
      // console.log("auth user poayload",{ payload });
      state.user = payload;
    },
    removeUser: (state) => {
      state.user = null;
    },
  },

  extraReducers: (builder) => {
    //Adminstrative profile:
    builder.addCase(fetchUserProfile.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchUserProfile.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.userProfile = payload;
      state.error = null;
    });

    builder.addCase(fetchUserProfile.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.error?.description;
    });

    //Member profile:
    builder.addCase(fetchMemberUserProfile.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchMemberUserProfile.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.memberProfile = payload;
      state.error = null;
    });

    builder.addCase(fetchMemberUserProfile.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.error?.description;
    });
  }

});

export default authSlice.reducer;
export const { removeUser, addLoginUser } = authSlice.actions;
