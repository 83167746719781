import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/settings";
import httpRequest from "../../utils/httpRequest";


const initialState = {
    bioData:[],
    pendingBioDataApproval: [],
    loading: false,
    bioDataDetail: "",
    error: null,
};

export const fetchBioData = createAsyncThunk(
    "membershipManagement/fetchBioData",
    async ({memberNumber, memberCategory }) => {
        return httpRequest(`${BASE_URL}/v2/MembershipManagement/BioData?memberNumber=${memberNumber}&memberCategory=${memberCategory}`);
    }
);

export const fetchPendingBioDataApproval = createAsyncThunk(
    "membershipManagement/fetchPendingBioDataApproval",
    async () => {
        return httpRequest(`${BASE_URL}/v2/MembershipManagement/PendingBioDataApproval?PageSize=1000`);
    }
)

export const updateBioData = createAsyncThunk(
    "membershipManagement/updateBioData",
    async ({memberId, arg}, { rejectWithValue }) => {
        try {
            const response = await httpRequest(
                `${BASE_URL}/v2/MembershipManagement/BioDataUpdate/${memberId}`,
                "post",
                arg
            );
            if (response?.response?.data) {
                throw response;
            }
            return response;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);

export const updateBioDataStatus = createAsyncThunk(
    "membershipManagement/updateBioDataStatus",
    async ( bioDataId, { rejectWithValue }) => {
        try {
            const response = await httpRequest(
                `${BASE_URL}/v2/MembershipManagement/updateBioDataStatus/${bioDataId}`,
                "put",
            );
            if (response?.response?.data) {
                throw response;
            }
            return response;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);

const membershipManagementSlice = createSlice({
    name: "membershipManagement",
    initialState,
    reducers: {
        setBioDataDetail: (state, { payload }) => {
            state.bioDataDetail = payload;
        }
    },
    extraReducers: (builder) => {
        // get Biodata:
        builder.addCase(fetchBioData.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(fetchBioData.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.bioData = payload;
            state.error = null;
        });

        builder.addCase(fetchBioData.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload?.error?.description;
        });

        // update Biodata:
        builder.addCase(updateBioData.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(updateBioData.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = null;
        });

        builder.addCase(updateBioData.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload?.error?.description;
        }); 

        // get Pending Biodata Approval:
        builder.addCase(fetchPendingBioDataApproval.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(fetchPendingBioDataApproval.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.pendingBioDataApproval = payload;
            state.error = null;
        });

        builder.addCase(fetchPendingBioDataApproval.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload?.error?.description;
        });

        // update Biodata Status:
        builder.addCase(updateBioDataStatus.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(updateBioDataStatus.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = null;
        });

        builder.addCase(updateBioDataStatus.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload?.error?.description;
        });
    }
});

export const { setBioDataDetail } = membershipManagementSlice.actions;
export default membershipManagementSlice.reducer;