import React, { useState } from 'react';
import { Box, Divider, Flex, Select, Text } from '@chakra-ui/react';


const UpcomingExams = ({upcomingEvents, title, showAdmin = true,}) => {
  const [showAll, setShowAll] = useState(false);
  const sortedEvents = upcomingEvents? [...upcomingEvents].sort((a, b) => new Date(a.date) - new Date(b.date)) : [];
  const displayedEvents = showAll ? sortedEvents : sortedEvents.slice(0, 2);

  return (
    <Box bg={'#FFFFFF'} borderRadius={'20px'} p={5} h={['100%','100%', showAdmin ? '345px': '400px']} width={'100%'}
    sx={{
      overflowY: "scroll",
      scrollbarWidth: "none", 
      msOverflowStyle: "none", 
      "-ms-overflow-style": "none", 
      "&::-webkit-scrollbar": {
        display: "none", 
      },
    }}
    overflowY={"auto"}
    >
      <Flex justifyContent={'space-between'} alignItems={'center'} >
        <Text color={'#4D4D4D'} letterSpacing={'-0.154px'} fontSize={'16px'} fontWeight={'500'}>
         {title}
        </Text>
        {
          showAdmin && 
          <Select w={'115px'} border={'0'} focusBorderColor='white' color={'#1E1E1E'} opacity={'0.5'} fontSize={'12px'} fontWeight={'400'} fontFamily={'inter'} lineHeight={'14.52px'}>
            <option>This Month</option>
          </Select>
        }
      </Flex>
      {displayedEvents && displayedEvents?.map((item, index, array) => (
          <React.Fragment key={item?.id}>
          <Flex alignItems={'center'} gap={3} py={4} >
            {
              showAdmin && 
              <Text color={'#4D4D4D'} fontSize={'14px'} fontWeight={'500'} fontFamily={'inter'} letterSpacing={'-0.154px'} w={'70px'} opacity={showAdmin ? '0.5': null} >
              {item?.date}
              </Text>
            }
              <Divider orientation='vertical' h="90px"  border={`2px solid ${index % 2 === 0 ? showAdmin ? '#CD73D1' : "#CD73D1" : showAdmin ? '#205070': '#D11A2A'}`} />
              <Flex gap={3} w={'170px'} >
                  <Flex flexDirection={'column'} color={'#1E1E1E'} fontSize={'12px'}>
                    {
                      !showAdmin && 
                    <Text color={'#4D4D4D'} fontSize={'14px'} fontWeight={'500'} fontFamily={'inter'} letterSpacing={'-0.154px'} w={'100px'} opacity={!showAdmin ? '0.5': null} >
                      {item?.date}
                    </Text>
                    }
                    <Text fontWeight={ showAdmin ? '400' : '700'} color={showAdmin ? '#1E1E1E' : '#222B45'} opacity={showAdmin ? '0.5': null}>{item?.title}</Text>
                    <Text fontWeight={ showAdmin? '700' : "400"} opacity={showAdmin ? '0.5': null} color={showAdmin ? '#1E1E1E' : '#222B45'}>{item?.attendee}</Text>
                  </Flex>
              </Flex>
          </Flex>
          </React.Fragment>
      ))}
      <Text color={'#16389A'} fontSize={'10px'} fontWeight={'400'} fontFamily={'inter'} lineHeight={'12.1px'} pt={6} textAlign={'center'} textDecoration={'underline'} opacity={'0.5'}
      onClick={() => setShowAll(!showAll)} cursor={'pointer'}>
        {showAll ? "View Less" : "View More"}
      </Text>
    </Box>
  );
};

export default UpcomingExams;