import React, { useEffect } from 'react';
import { Avatar, Box, Button, Flex, Image, Text } from '@chakra-ui/react';
import logo from "../Assets/Images/icsan-logo.svg";
import { BiLeftArrowAlt } from "react-icons/bi";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fecthExamDocket} from '../redux/slices/examMgtSlice';
import { File_URL } from "../config/settings";


const ExamPhotoCard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const examYear = searchParams.get("ExamYear");
  const examDiet = searchParams.get("ExamDietId");

  const examDocketData = useSelector((state) => state?.examMgt?.examDocket)

  useEffect(() => {
    dispatch(fecthExamDocket({ExamYear:examYear,ExamDietId:examDiet }))
  }, [dispatch, examYear, examDiet])


  const personalDetails = [
    { title: "Student Number:", description: examDocketData?.studentNumber},
    { title: "Full Name:", description: examDocketData?.fullName},
    { title: "Category:", description: examDocketData?.category },
  ];

  const examDetails = [
    { title: "Exam Number:", description: examDocketData?.examNumber },
    { title: "Subject:", description: examDocketData?.subject?.map((item) => item?.name) },
  ];

  return (
    <Box bgColor={'#FFFFFF'} minH={'80vh'} mt={1} px={['20px', '30px', '50px']} py={'20px'}>
      <Box mb={5} w={'30px'} h={'30px'} bg={'#F9A36238'} rounded={'full'} cursor={'pointer'} p={1} onClick={() => navigate(-1)}>
        <BiLeftArrowAlt color="#D8991F" size={'20px'} />
      </Box>
      <Flex direction={'column'} gap={4} >
        <Image src={logo} alt='ICSAN Logo' w={'121px'} h={'62px'} />
        <Text color={'#1E1E1E'} fontSize={['16px', '20px', '24px']} fontWeight={'700'} lineHeight={'36.7px'}>INSTITUTE OF CHATERED SECRETARIES AND ADMINISTRATORS OF NIGERIA</Text>
        <Text color={'#1E1E1E'} fontSize={'14px'} fontWeight={'400'} lineHeight={'21.41px'}>Exam Photocard</Text>
      </Flex>
      <Flex flexDirection={['column-reverse', "row", "row"]} alignItems={'stretch'} gap={5} my={10}>
        <Box w={["100%", "60%", "70%"]}>
          <Flex alignItems={'center'} gap={3} bgColor={'#D7343926'} px={['20px', '40px', '40px']} py={'20px'}>
            <Text color={'#222B45'} fontWeight={'700'} fontSize={'14px'} lineHeight={'16px'} >Personal Details</Text>
          </Flex>
          <Box bgColor={'#FFFFFF'} px={['20px', '40px', '40px']} py={'30px'} border={'1px solid #C6C6C640'}>
            {personalDetails && personalDetails?.map((item, index) => (
              <Flex key={index} alignItems={'center'} gap={5} >
                <Text mb={5} color={'#000000'} fontWeight={'700'} fontSize={'16px'} lineHeight={'24.46px'} opacity={'0.5'} width={'30%'}>{item?.title}</Text>
                <Text mb={5} color={'#000000'} fontWeight={'700'} fontSize={'16px'} lineHeight={'24.46px'} opacity={'0.5'} width={'70%'}>{item?.description}</Text>
              </Flex>
            ))}
          </Box>
        </Box>
        <Flex justifyContent={'center'} alignItems={'center'} w={["100%", "40%", "30%"]} border={'1px solid #C6C6C640'} >
        <Avatar src={`${File_URL}${examDocketData?.passport}`} border={'5px solid #D11A2A'} size='2xl' />
        </Flex>
      </Flex>
      <Box mb={10} >
        <Flex alignItems={'center'} gap={3} bgColor={'#D7343926'} px={['20px', '40px', '40px']} py={'20px'}>
          <Text color={'#222B45'} fontWeight={'700'} fontSize={'14px'} lineHeight={'16px'} >Exam Details</Text>
        </Flex>
        <Box bgColor={'#FFFFFF'} px={['20px', '40px', '40px']} py={'30px'} border={'1px solid #C6C6C640'}>
          {examDetails && examDetails?.map((item, index) => (
            <Flex key={index} alignItems={'center'} gap={5} >
              <Text mb={5} color={'#000000'} fontWeight={'700'} fontSize={'16px'} lineHeight={'24.46px'} opacity={'0.5'} width={'20%'}>{item?.title}</Text>
              <Text mb={5} color={'#000000'} fontWeight={'700'} fontSize={'16px'} lineHeight={'24.46px'} opacity={'0.5'} width={'70%'}>{item?.description}</Text>
            </Flex>
          ))}
        </Box>
      </Box>
      <Button mb={5} px={10} bgColor={'#D11A2A'} color={'#FFFFFF'} fontWeight={'500'} fontSize={'14px'} lineHeight={'16px'} borderRadius={'5px'} _hover={{ bgColor: '' }} _active={{ bgColor: '' }}
      >Print Photocard</Button>
    </Box>
  )
}

export default ExamPhotoCard;