import React, {useState} from 'react';
import { Box, Menu, MenuButton, MenuItem, MenuList, Alert,AlertIcon, AlertTitle } from '@chakra-ui/react';
import {BsThreeDotsVertical} from 'react-icons/bs';
import CustomTable from './CustomTable';
import { useDispatch, useSelector } from 'react-redux';
import {setUserDetails, setEditUser} from "../redux/slices/userMgtUsersSlice";
import { useNavigate } from 'react-router-dom';
import {setFormModal} from "../redux/slices/modalSlice";
import { checkPermissions} from '../components/CheckPermission';
import { BASE_URL } from "../config/settings";
import {setloadTost} from "../redux/slices/toastSlice";
import {fetchUsers} from "../redux/slices/userMgtUsersSlice";
import httpRequest from "../utils/httpRequest";

const UserMgtTable = () => {
  const dispatch =  useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const errorsArray = Array.isArray(error) ? error : [error];
  const userData = useSelector((state) => state?.userMgtUsers?.allUsers);
  const editUserPermission = checkPermissions( 'User Management', 'Users', 'Edit', 'Granted' );
  const deleteUserPermission = checkPermissions( 'User Management', 'Users', 'Delete', 'Granted' );
    
  const handleEdit = (item) => {
    dispatch(setEditUser(item));
    dispatch(setFormModal({status:true, title: "Edit User", type:"editUser",}));
  }
  const handleUpdate = (item) => {
    dispatch(setUserDetails(item));
    navigate('/usermanagement/user-profile');
  }

  const handleDeleteUser = async (item) => {
      // console.log(item);
      setError('');
     try {
        const response = await httpRequest(`${BASE_URL}/v2/Administrative/${item?.email}/delete`,
        'delete', 
      );
      // console.log(response);
      if(response?.isSuccess === true){
        setError('');
        dispatch(fetchUsers());
        dispatch(setloadTost({ title: 'User Deleted', description: `${item?.firstName} ${item?.lastName} Deleted successfully`, status: 'success', duration: 5000, isClosable: true, position: 'top' }));
     }else{
        setError(response?.response?.data?.errors?.map((errors) => errors) || response?.response?.data?.error?.description || "Failed to delete user");
      }
      
     } catch (error) {
      setError(error?.response?.message || "Failed to delete user");
     }

  }

  const handleChangeStatus = async (item) => {
    // console.log(item);
    setError('');
    try{
      if(item?.isActive === true){
        const response = await httpRequest(
          `${BASE_URL}/v2/Administrative/${item?.id}/status?active=false`,
          'put',
        );
        // console.log(response);
        if(response?.isSuccess === true){
          setError('');
          dispatch(fetchUsers());
          dispatch(setloadTost({ title: 'User Deactivated', description: `${item?.firstName} ${item?.lastName} Deactivated successfully`, status: 'success', duration: 5000, isClosable: true, position: 'top' }));
        }else{
          setError(response?.response?.data?.errors?.map((errors) => errors) || response?.response?.data?.error?.description || "Failed to deactivate user");
        }
      }else{
        const response = await httpRequest(
          `${BASE_URL}/v2/Administrative/${item?.id}/status?active=true`,
          'put',
        );
        // console.log(response);
        if(response?.isSuccess === true){
          setError('');
          dispatch(fetchUsers());
          dispatch(setloadTost({ title: 'User Activated', description: `${item?.firstName} ${item?.lastName} Activated successfully`, status: 'success', duration: 5000, isClosable: true, position: 'top' }));
        }else{
          setError(response?.response?.data?.errors?.map((errors) => errors) || response?.response?.data?.error?.description || "Failed to Activate user");
        }
      }

    }catch{
      setError( error?.response?.message ||"Request Failed");
    }
  }

  const tableProps = {
    data: userData?.data ,
    title: {
        staffId: "Staff Id",
        fullName: "Full Name",
        role: "Role",
        email:"Email",
        department: "Department",
        status: "Status",
        Action:'',
    },
    dataKeys: [
      "staffId",
      "fullName",
      "role",
      "email",
      "department",
      "status",
      "Action",
    ],
    sn: false,
    transformRow: (item) => ({
      staffId: <div style={{cursor:'pointer'}} onClick={() => handleUpdate(item)}>{item?.staffId}</div>,
      fullName: <div style={{cursor:'pointer'}} onClick={() => handleUpdate(item)}>{item?.firstName} {item?.lastName}</div>,
      role: <div style={{cursor:'pointer'}} onClick={() => handleUpdate(item)}>{item?.role}</div>,
      email: <div style={{cursor:'pointer'}} onClick={() => handleUpdate(item)}>{item?.email}</div>,
      department: <div style={{cursor:'pointer'}} onClick={() => handleUpdate(item)}>{item?.department}</div>,
      status: item &&  (
        <Box
          style={{ cursor: "pointer" }}
          color={item?.isActive === true ? "#0ADB13" : "#D11A2A"}
        >
          {item?.isActive === true ? "Active" : "Inactive"}
        </Box>
      ),
      Action: item && (
          <Menu>
            {
              !editUserPermission && !deleteUserPermission ? null : (
                <MenuButton>
                  <BsThreeDotsVertical />
                </MenuButton>
              )
            }
          <MenuList >
            {
              editUserPermission? (
                <MenuItem onClick={() => handleEdit(item)}>Edit User</MenuItem>
              ) : null
            }
            {
              editUserPermission? (
                <MenuItem onClick={() => handleChangeStatus(item)}>{item?.isActive === true ? "Deactivate User" : "Activate User"}</MenuItem>
              ) : null
            }
            {
              deleteUserPermission? (
                <MenuItem onClick={() => handleDeleteUser(item)}> Delete User</MenuItem>
              ) : null
            }
          </MenuList>
          </Menu>
      )
    }),
    
  };

  return (
    <Box>
      {
        error &&(
        <Alert status="error" className="mt-3" mb={3}>
            <AlertIcon />
            <AlertTitle>{errorsArray.join(', ')}</AlertTitle>
        </Alert>
        )
        }
      <CustomTable {...tableProps } />
    </Box>
  )
}

export default UserMgtTable;